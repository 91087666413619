import { AUTHENTICATION } from '../actions';

const authentication = (state = false, action: any) => {
  switch(action.type) {
    case AUTHENTICATION:
      return action.isAuthen;
    default: return state;
  }
}

export default authentication;