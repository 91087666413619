import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button } from 'antd';
import { authentication } from '../../store/actions';
import Authen from '../../services/Authentication';
import UsersService from '../../services/Users'
import 'antd/dist/antd.css';
import './Login.css';

class Login extends Component<any, any> {
  userService = new UsersService()
  state = {
    errorMessage: null,
    logging: false
  }
  componentDidMount() {
    document.title = document.title + ' - Login';
    if (this.props.location.search.indexOf('logout') > -1) {
      Authen.clearLocalStorage();
    } else {
      const isEpired = Authen.isTokenExpired();
      if (!isEpired) {
        this.redirect();
      } else {
        Authen.clearLocalStorage();
      }
    }
  }
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        this.setState({ logging: true });
        const logged = await Authen.login(values.username, values.password);
        this.userService.saveActivity({
          moduleName:'Login',
          actionName: 'success'
        })
        this.setState({ logging: false });
        if (logged) {
          this.redirect();
        } else {
          Authen.clearLocalStorage();
          this.setState({ errorMessage: 'Invalid Login or password.' });
        }
      }
    });
  };

  redirect = () => {
    this.props.setAuthen(true);
    let path = '/';
    if (this.props.location.state && this.props.location.state.from) {
      path = this.props.location.state.from.pathname;
    }
    this.props.history.replace(path);
    // window.location.href = path;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="components-form-login">
        <div className="logo-login"></div>
        <Form onSubmit={this.handleSubmit} onChange={() => { this.setState({ errorMessage: null }) }} className="login-form">
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Please input your username!' }],
            })(
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Username"
                data-at="field-username"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your Password!' }],
            })(
              <Input.Password
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
                data-at="field-password"
              />,
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" 
              htmlType="submit" 
              className="login-form-button" 
              data-at="btn-submit"
              loading={this.state.logging}
            >
              Log in
          </Button>
            <p className="err-message" data-at="label_error" >{this.state.errorMessage}</p>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const WrappedLoginForm = Form.create({ name: 'normal_login' })(Login);

const mapDispatchToProps = (dispatch: any) => ({
  setAuthen: (isAuthen: boolean) => dispatch(authentication(isAuthen)),
})

export default connect(undefined, mapDispatchToProps)(WrappedLoginForm);