import React, { Component } from "react";
import { Button, Table, Form, Select, Row, Col, Icon } from "antd";
import moment from 'moment';
import UsersService from '../../services/Users';
import './Users.css';

class UsersActitity extends Component<any, any> {
    userService = new UsersService()

    state = {
        dataTable: undefined,
        pagination: {
            current: 1,
            defaultPageSize: 10,
            pageSize: 10,
            total: 0
        },
        loading: true,
        moduleList: [],
        actionList: [],
        userList: [],
        filter: {
            module: undefined,
            action: undefined,
            user: undefined
        }
    }

    componentDidMount() {
        this.fetch()
        this.getFilterOptions()
    }

    async getFilterOptions() {
        const moduleList = await this.userService.getUserActivityModule()
        const actionList = await this.userService.getUserActivityAction()
        const userList = await this.userService.retrieveUsers()
        this.setState({
            moduleList: moduleList.data,
            actionList: actionList.data,
            userList: userList[0]
        })
    }

    fetch = async (params?: any) => {
        const current = params&&params.current?params.current:this.state.pagination.current
        const result = await this.userService.activity({current, ...this.state.filter})
        if (result.data) {
            this.setState({
                dataTable: result.data,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    total: Number(result.totalRows),
                    current
                }
            })
        }
    }

    handleTableChange = async (pagination: any, filters: any, sorter: any) => {
        this.fetch({ current: pagination.current });
    }

    handlerSubmit = (e: any) => {
        e.preventDefault()
        this.props.form.validateFields((err: any, values: any) => {
            if (!err) {
                this.setState({filter: {...values}}, () => {
                    this.fetch({current: 1})
                })
            }
        })
    }

    handleClearFilter = () => {
        this.props.form.resetFields()
        this.setState({filter: {
            module: undefined,
            action: undefined,
            user: undefined
        }}, () => {
            this.fetch({current: 1})
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form
        const columns = [
            {
                title: "No.",
                key: "no",
                render: (text: any, record: any, index: number) => {
                    return index+1+((this.state.pagination.current-1)*this.state.pagination.defaultPageSize)
                }
            },
            {
                title: 'Datetime',
                key: 'createdAt',
                render: (text: any, record: any) => {
                    return moment(record.createdAt).format('YYYY-MM-DD HH:mm')
                }
            },
            {
                title: 'Module',
                key: 'moduleName',
                render: (text: any, record: any) => {
                    return record.moduleName.charAt(0).toUpperCase() + record.moduleName.slice(1)
                }
            },
            {
                title: 'Action',
                dataIndex: 'actionName',
                key: 'actionName',
                render: (text: any, record: any) => {
                    return record.actionName.charAt(0).toUpperCase() + record.actionName.slice(1)
                }
            },
            {
                title: 'Content ID',
                key: 'contentId',
                dataIndex: 'contentId',
                render: (text: any, record: any) => {
                    return record.contentId?record.contentId:'-'
                }
            },
            {
                title: 'User',
                dataIndex: 'firstname',
                key: 'firstname',
                render: (text: any, record: any) => {
                    return `${record.firstname} ${record.lastname}`
                }
            }
        ]
        return (
            <div className="user-list">
                {/* <div className="input-filter"> */}
                    <Form layout="horizontal" onSubmit={this.handlerSubmit}>
                        <Row>
                            <Col xxl={5} xl={6} sm={8}>
                                <Form.Item wrapperCol={{xxl: 23, xl: 22, sm: 22}} label="Module">
                                    {getFieldDecorator('module')(
                                        <Select placeholder="Select Module">
                                            {this.state.moduleList.map((value: any, dataIndex)=><Select.Option key={`module-${dataIndex}`} value={value.module}>{value.module.charAt(0).toUpperCase() + value.module.slice(1)}</Select.Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xxl={5} xl={6} sm={8}>
                                <Form.Item wrapperCol={{xxl: 23, xl: 22, sm: 22}} label="Action">
                                    {getFieldDecorator('action')(
                                        <Select placeholder="Select Action">
                                            {this.state.actionList.map((value: any, dataIndex)=><Select.Option key={`action-${dataIndex}`} value={value.action}>{value.action.charAt(0).toUpperCase() + value.action.slice(1)}</Select.Option>)}
                                        </Select>
                                    )} 
                                </Form.Item>
                            </Col>
                            <Col xxl={5} xl={6} sm={8}>
                                <Form.Item wrapperCol={{xxl: 23, xl: 22, sm: 22}} label="User">
                                    {getFieldDecorator('user')(
                                        <Select placeholder="Select User">
                                            {this.state.userList.map((value: any, dataIndex)=><Select.Option key={`user-${dataIndex}`} value={value.id}>{value.firstname+' '+value.lastname}</Select.Option>)}
                                        </Select>
                                    )} 
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary"><Icon type="search" />Search</Button>
                                    <Button type="default" onClick={this.handleClearFilter} style={{marginLeft: 10}}>
                                        <Icon type="undo"/>
                                        Clear
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    
                {/* </div> */}
                <div className="card-table">
                <Table columns={columns}
                    rowKey={(record: any) => record.id}
                    loading={this.state.loading}
                    pagination={this.state.pagination}
                    dataSource={this.state.dataTable}
                    onChange={this.handleTableChange} />
                </div>
            </div>
        )
    }
}

export default Form.create()(UsersActitity);