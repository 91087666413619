import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Route, BrowserRouter, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import IdleTimer from "react-idle-timer";
import "antd/dist/antd.css";

import Authen from "../../services/Authentication";
import PrivateRoute from "../../common/PrivateRoute";
import Login from "../login/Login";
import Dashboard from "../dashboard/Dashboard";
import Customer from "../customer/Customer";
import Message from "../message/Message";
import ProductCategory from "../product-category/ProductCategory";
import ProductCategoryForm from "../product-category/form/ProductCategoryForm";
import CarouselManagement from "../product-category/carousel/Carousel";
import Product from "../product/Product";
import ProductForm from "../product/form/ProductForm";
import Privilege from "../privilege/Privilege";
import PrivilegeForm from "../privilege/form/PrivilegeForm";
import Users from "../users/Users";
import UserForm from "../users/form/UserForm";
import UserActivity from "../users/UsersActivity";
import ProfileForm from "../users/profile/ProfileForm";
import RichMenu from "../richmenu/RichMenu";
import RichMenuForm from "../richmenu/form/RichMenuForm";
import Hospital from "../location/Hospital";
import Branch from "../location/Branch";
import TrackingUrl from "../tracking-url/TrackingUrl";
import LocationForm from "../location/form/LocationForm";
import LineCC from "../linecc/LineCC";
import PageNotFound from "../pagenotfound/PageNotFound";
import AgentBacklist from "../agent-backlist/AgentBacklist";
import AgentBacklistMonthly from "../agent-backlist/monthly/AgentBacklistMonthly";
import Maintenance from "../maintenance/Maintenance";
import TrackingUrlForm from "../tracking-url/form/TrackingUrlForm";

export const history = createBrowserHistory();

class App extends Component<any, any> {
  idleTimer: any;
  onAction: any;
  onActive: any;
  onIdle: any;
  state = {
    isAuthen: false,
    isIdle: false,
  };

  constructor(props: any) {
    super(props);
    document.title = "LINE x MTL - CMS";
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }
  _onAction(e: any) {
    this.setState({ isIdle: false });
  }

  _onActive(e: any) {
    this.setState({ isIdle: false });
  }

  _onIdle(e: any) {
    this.setState({ isIdle: true });
  }

  async componentWillMount() {
    await this.setState({
      isAuthen: !Authen.isTokenExpired(),
    });
    this.refreshTimeout();
  }

  async componentWillReceiveProps(newProps: any) {
    if (newProps !== this.props) {
      const isAuthen = newProps.isAuthen;
      this.setState({ isAuthen });
      if (isAuthen) {
        this.refreshTimeout();
      }
    }
  }

  async getRefreshToken() {
    const refreshToken = window.localStorage.getItem("refresh_token");
    if (refreshToken) {
      const newToken = await Authen.getRefresh(refreshToken);
      if (newToken) {
        let refreshExpired = new Date();
        refreshExpired.setTime(
          refreshExpired.getTime() + 4 * 60 * 1000 + 55 * 1000
        );
        localStorage.setItem("refresh_token", newToken);
        localStorage.setItem(
          "refresh_expired",
          refreshExpired.getTime().toString()
        );
        this.refreshTimeout();
      }
    }
  }

  async refreshTimeout() {
    const refreshExpire = window.localStorage.getItem("refresh_expired");
    if (refreshExpire) {
      const now = new Date();
      const expired = new Date(+refreshExpire);
      const timeout = expired.getTime() - now.getTime();
      console.log(`refresh token in ${Math.round(timeout / 1000)}s`);
      if (timeout) {
        setTimeout(() => {
          console.log("timeout");
          if (!this.state.isIdle) {
            console.log("go to get refresh token");
            this.getRefreshToken();
          } else {
            console.log("session expired login");
            alert("session expired! please login again");
            history.push("/login?logout");
          }
        }, timeout);
      }
    }
  }

  render() {
    const isAuthen = this.state.isAuthen;
    const userRole = window.localStorage.getItem("role");
    return (
      <React.Fragment>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15}
        />
        <BrowserRouter>
          <Router history={history}>
            <Switch>
              <Route exact path="/login" component={Login} />
              <PrivateRoute
                exact
                path="/"
                isAuthenticated={isAuthen}
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path="/customer"
                isAuthenticated={isAuthen}
                component={Customer}
              />
              <PrivateRoute
                exact
                path="/message"
                isAuthenticated={isAuthen}
                component={Message}
              />
              <PrivateRoute
                exact
                path="/profile"
                isAuthenticated={isAuthen}
                component={ProfileForm}
              />
              <PrivateRoute
                exact
                path="/product-category"
                isAuthenticated={isAuthen}
                component={ProductCategory}
              />
              <PrivateRoute
                exact
                path="/product-category/carousel"
                isAuthenticated={isAuthen}
                component={CarouselManagement}
              />
              <PrivateRoute
                exact
                path="/product-category/carousel/:id"
                isAuthenticated={isAuthen}
                component={CarouselManagement}
              />
              <PrivateRoute
                exact
                path="/product-category/product"
                isAuthenticated={isAuthen}
                component={Product}
              />
              <PrivateRoute
                exact
                path="/product-category/product/:id"
                isAuthenticated={isAuthen}
                component={ProductForm}
              />
              <PrivateRoute
                exact
                path="/product-category/create"
                isAuthenticated={isAuthen}
                component={ProductCategoryForm}
              />
              <PrivateRoute
                exact
                path="/product-category/:id"
                isAuthenticated={isAuthen}
                component={ProductCategoryForm}
              />
              <PrivateRoute
                exact
                path="/tracking-url"
                isAuthenticated={isAuthen}
                component={TrackingUrl}
              />
              <PrivateRoute
                exact
                path="/tracking-url/create"
                isAuthenticated={isAuthen}
                component={TrackingUrlForm}
              />
              <PrivateRoute
                exact
                path="/tracking-url/:id"
                isAuthenticated={isAuthen}
                component={TrackingUrlForm}
              />
              <PrivateRoute
                exact
                path="/privilege"
                isAuthenticated={isAuthen}
                component={Privilege}
              />
              <PrivateRoute
                exact
                path="/privilege/:id"
                isAuthenticated={isAuthen}
                component={PrivilegeForm}
              />
              {userRole === "admin" ? (
                <PrivateRoute
                  exact
                  path="/users"
                  isAuthenticated={isAuthen}
                  component={Users}
                />
              ) : null}
              {userRole === "admin" ? (
                <PrivateRoute
                  exact
                  path="/users/:id"
                  isAuthenticated={isAuthen}
                  component={UserForm}
                />
              ) : null}
              {userRole === "admin" ? (
                <PrivateRoute
                  exact
                  path="/users-activity"
                  isAuthenticated={isAuthen}
                  component={UserActivity}
                />
              ) : null}
              <PrivateRoute
                exact
                path="/richmenu"
                isAuthenticated={isAuthen}
                component={RichMenu}
              />
              <PrivateRoute
                exact
                path="/richmenu/:id"
                isAuthenticated={isAuthen}
                component={RichMenuForm}
              />
              <PrivateRoute
                exact
                path="/location/hospital"
                isAuthenticated={isAuthen}
                component={Hospital}
              />
              <PrivateRoute
                exact
                path="/location/branch"
                isAuthenticated={isAuthen}
                component={Branch}
              />
              <PrivateRoute
                exact
                path="/location/:type/:id"
                isAuthenticated={isAuthen}
                component={LocationForm}
              />
              <PrivateRoute
                exact
                path="/linecc"
                isAuthenticated={isAuthen}
                component={LineCC}
              />
              <PrivateRoute
                exact
                path="/agent-backlist"
                isAuthenticated={isAuthen}
                component={AgentBacklist}
              />
              <PrivateRoute
                exact
                path="/agent-backlist/monthly"
                isAuthenticated={isAuthen}
                component={AgentBacklistMonthly}
              />
              <PrivateRoute
                exact
                path="/maintenance"
                isAuthenticated={isAuthen}
                component={Maintenance}
              />
              <Route exact component={PageNotFound} />
            </Switch>
          </Router>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isAuthen: state.authentication,
  };
};

export default connect(mapStateToProps)(App);
