import React, { Component } from 'react';
import { Table, Button, Input, DatePicker, message, Avatar, Modal, Select } from 'antd';
import moment from 'moment';
import './Customer.css';
import CustomerService from '../../services/Customer';
import UsersService from '../../services/Users'

const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Option } = Select;

class Customer extends Component<any, any> {
  customerService = new CustomerService();
  userService = new UsersService()
  columns = [
    {
      title: 'Datetime',
      key: 'created_date',
      dataIndex: 'created_date',
      render: (text: any, record: any) => {
        const date = moment(record.created_date).format('YYYY-MM-DD');
        return <span data-at={'label_date-' + record.uid}>{date}</span>
      },
      sorter: true,
    },    
    {
      title: 'Client ID',
      dataIndex: 'client_no',
      key: 'client_no',
      sorter: true,
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'tel',
      key: 'tel',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'profile_display',
      key: 'profile_display',
      sorter: true,
      render: (text: any, record: any) => {
        return <span data-at={'label_name-' + record.uid}>{text}</span>
      }
    },
    {
      title: 'Picture Profile',
      dataIndex: 'picture_url',
      key: 'picture_url',
      render: (text: any, record: any) => {
        return <Avatar data-at={'picture_profile-' + record.uid} icon="user" size="large" src={record.picture_url} />
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text: any, record: any) => {
        const status = text === 'active' ? 'Member' : 'Guest';
        return <span data-at={'label_status-' + record.uid}>{status}</span>
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => {
        return (
          <React.Fragment>
            <Button type="danger"
              data-at={'btn_unlink-' + record.uid}
              disabled={record.status !== 'active'}
              onClick={() => this.handleBtnUnlink(record)}
              className="btn-custom-red"
              ghost>Cancel Registration</Button>
            {/* <span onClick={() => this.handleBtnLink(record)} style={{marginLeft: '8px'}} >register</span> */}
          </React.Fragment>
        );
      },
    }
  ];

  state = {
    dateSearch: [],
    nameSearch: undefined,
    statusSearch: undefined,
    clientIdSearch: undefined,
    dataTable: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    },
    loading: false,
    exporting: false,
    status: ['all', 'active', 'inactive'],
    role: undefined,
  }

  async componentWillMount() {
    document.title = "LINE x MTL - CMS Customer";
  }

  async componentDidMount() {
    const userRole = window.localStorage.getItem("role");
    this.setState({ role: userRole });
    this.fetch();
    this.userService.saveActivity({
      moduleName:'customer',
      actionName: 'view'
    })
  }

  handleStatus = (status: any) => {
    this.setState({ statusSearch: status });
  }

  handleBtnUnlink = async (record: any) => {
    const vm = this;
    confirm({
      title: 'Do you want to cancel registration?',
      content: `${record.profile_display} will cancel registration`,
      async onOk() {
        const result = await vm.customerService.unlinkCustomer(record);
        if (result) {
          const dataTable = vm.state.dataTable;
          dataTable.map((row: any) => {
            if (row.uid === record.uid) {
              row.status = (row.status === 'active') ? 'inactive' : 'active';
            }
            return row;
          });
          vm.userService.saveActivity({
            moduleName:'customer',
            actionName: 'cancel-registration',
            contentId: record.client_no,
          })
          vm.setState({ dataTable });
          message.success('update successful');
        } else {
          message.error('update failed');
        }
      }
    });
  }

  handleBtnLink = async (record: any) => {
    const vm = this;
    confirm({
      title: 'Do you want to link user?',
      content: `${record.profile_display} will link`,
      async onOk() {
        const result = await vm.customerService.linkCustomer(record);
        if (result) {
          const dataTable = vm.state.dataTable;
          dataTable.map((row: any) => {
            if (row.uid === record.uid) {
              row.status = (row.status === 'active') ? 'inactive' : 'active';
            }
            return row;
          });
          vm.setState({ dataTable });
          message.success('update successful');
        } else {
          message.error('update failed');
        }
      }
    })
  }

  handleSearch = () => {
    this.fetch();
  }

  handleClear = async () => {
    await this.setState({ dateSearch: [], nameSearch: undefined, statusSearch: undefined, clientIdSearch: undefined });
    this.fetch();
  }

  handleDatePicker = (e: any) => {
    this.setState({ dateSearch: e })
  }

  handleNameChange = (e: any) => {
    this.setState({ nameSearch: e.target.value });
  }

  handleClientChange = (e: any) => {
    this.setState({ clientIdSearch: e.target.value });
  }

  handleExport = async (e: any) => {
    this.setState({ exporting: true });
    let params: any = {};
    if (this.state.dateSearch.length) {
      let d1 = moment(this.state.dateSearch[0]).format('YYYY-MM-DD');
      let d2 = moment(this.state.dateSearch[1]).format('YYYY-MM-DD');
      const createdDate = `${d1},${d2}`;
      params.created_date = createdDate;
    }
    if (this.state.nameSearch) {
      params.name = this.state.nameSearch;
    }
    if (this.state.statusSearch) {
      params.status = this.state.statusSearch;
    }
    if (this.state.clientIdSearch) {
      params.clientId = this.state.clientIdSearch;
    }
    await this.customerService.retrieveCustomerCSV(params);
    this.setState({ exporting: false });
    this.userService.saveActivity({
      moduleName:'customer',
      actionName: 'export'
    })
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({ pagination: pager });
    if (sorter.field) {
      this.fetch({ page: pager.current, limit: pager.pageSize, sorting: sorter.field, order: sorter.order });

    } else {
      this.fetch({ page: pager.current, limit: pager.pageSize });
    }
  }

  fetch = async (params: any = {}) => {
    if (this.state.dateSearch.length) {
      let d1 = moment(this.state.dateSearch[0]).format('YYYY-MM-DD');
      let d2 = moment(this.state.dateSearch[1]).format('YYYY-MM-DD');
      const createdDate = `${d1},${d2}`;
      params.created_date = createdDate;
    }
    if (this.state.nameSearch) {
      params.name = this.state.nameSearch;
    }
    if (this.state.statusSearch) {
      params.status = this.state.statusSearch;
    }
    if (this.state.clientIdSearch) {
      params.clientId = this.state.clientIdSearch;
    }
    this.setState({ loading: true });
    let dataTable: any = this.state.dataTable;
    const pagination = { ...this.state.pagination };
    const result = await this.customerService.retrieveCustomer(params);
    if (result) {
      if (result[0].length > 0)
        dataTable = result[0];
      else dataTable = [];
      pagination.total = result[1];
      this.setState({
        dataTable,
        pagination,
      });
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <div className="customer">
        <div className="input-filter">
          <div className="input-item">
            <span className="text">Date</span>
            <RangePicker className="input"
              data-id="datepicker"
              onChange={this.handleDatePicker}
              value={this.state.dateSearch}
              data-at="field-dateRange"
            />
          </div>
          <div className="input-item" >
            <span className="text">Name</span>
            <Input placeholder="Search"
              onChange={this.handleNameChange}
              value={this.state.nameSearch}
              className="input"
              data-at="field-name"
              onKeyUp={(e)=> e.keyCode === 13 ? this.handleSearch(): ''}
            />
          </div>
          <div className="input-item" >
            <span className="text">Client ID</span>
            <Input placeholder="Client ID"
              onChange={this.handleClientChange}
              value={this.state.clientIdSearch}
              className="input"
              data-at="field-name"
              onKeyUp={(e)=> e.keyCode === 13 ? this.handleSearch(): ''}
            />
          </div>
          <div className="input-item short">
            <span className="text">Status</span>
            <Select
              className="input"
              placeholder="All"
              data-at="field-status"
              onChange={this.handleStatus}
              value={this.state.statusSearch}
            >
              <Option value="all" key="all" >All</Option>
              <Option value="inactive" key="inactive" >Guest</Option>
              <Option value="active" key="active" >Member</Option>
            </Select>
          </div>
          <div className="input-item button">
            <span className="text"></span>
            <div className="btn">
              <Button type="primary"
                data-id="search-btn"
                icon="search"
                onClick={() => this.handleSearch()}
                data-at="btn-search"
              >
                Search
              </Button>
              <Button type="default"
                onClick={() => this.handleClear()}
                icon="sync"
                data-at="btn-clear"
                className="btn-custom-export"
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
        <div className="export">
          <Button 
            type="default" 
            icon="download" 
            onClick={this.handleExport}
            loading={this.state.exporting}
            disabled={this.state.exporting}
            data-at="btn-export"
            className="btn-custom-export"
            style={{display: this.state.role !== 'admin' ? 'none': ''}}
          >
            Export
          </Button>
        </div>
        <div className="card-table">
          <Table columns={this.columns}
            rowKey={(rocord: any) => rocord.uid}
            pagination={this.state.pagination}
            loading={this.state.loading}
            dataSource={this.state.dataTable}
            onChange={this.handleTableChange} />
        </div>
      </div>
    );
  }
}

export default Customer;