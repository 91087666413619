import React, { Component } from "react";
import { DatePicker, Input, Button, Table, Modal, Select, message } from "antd";
import TrackingUrlService from "../../services/TrackingUrl";
import moment from "moment";
import "./TrackingUrl.css";
import UsersService from "../../services/Users";

const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Option } = Select;
const URL = process.env.REACT_APP_TRACKING_URL;

class TrackingUrl extends Component<any, any> {
  trackingUrlService = new TrackingUrlService();
  userService = new UsersService();
  columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "90px",
      sorter: true,
      render: (text: any) => {
        return <span data-at={"label_id-" + text}>{text}</span>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text: any, record: any) => {
        return (
          <span
            data-at={"label_name-" + record.id}
            style={{ fontSize: "16px", fontFamily: "Kalatexa" }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Link Url",
      dataIndex: "tracking_url",
      key: "tracking_url",
      sorter: true,
      render: (text: any, record: any) => {
        return <span data-at={"tracking_url-" + record.id}>{text}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "110px",
      render: (text: any, record: any) => {
        const status = text === "A" ? "Active" : "Inactive";
        return <span data-at={"label_status-" + record.id}>{status}</span>;
      },
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      width: "40%",
      render: (text: any, record: any) => (
        <div className="btn-row">
          <Button
            ghost
            type="primary"
            icon="copy"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(
                `${URL}?key=${record.tracking_key}`
              );
              message.info("Copy Link Url");
            }}
            data-at={"btn_carousel-" + record.id}
          >
            Copy Url
          </Button>
          <Button
            ghost
            type="primary"
            icon="edit"
            size="small"
            onClick={() => this.handleEditTrackingUrl(record)}
            data-at={"btn_edit-" + record.id}
            className="btn-custom-blue"
          >
            Edit
          </Button>
          <Button
            ghost
            type="danger"
            icon="delete"
            size="small"
            className="btn-custom-red"
            onClick={() => this.handleDeleteTrackingUrl(record)}
            data-at={"btn_delete-" + record.id}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  state = {
    dateSearch: [],
    nameSearch: undefined,
    statusSearch: undefined,
    linkSearch: undefined,
    dataTable: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    loading: false,
    exporting: false,
    role: undefined,
  };

  componentWillMount() {
    document.title = "LINE x MTL - CMS Tracking Url";
  }

  componentDidMount() {
    const userRole = window.localStorage.getItem("role");
    this.setState({ role: userRole });
    this.fetch();
    this.userService.saveActivity({
      moduleName: "tracking url",
      actionName: "view",
    });
  }

  componentWillUnmount() {
    delete this.trackingUrlService;
  }

  handleDatepicker = (dateMoment: any) => {
    this.setState({ dateSearch: dateMoment });
  };

  handleName = (e: any) => {
    const value = e.target.value;
    this.setState({ nameSearch: value });
  };
  handleLink = (e: any) => {
    const value = e.target.value;
    this.setState({ linkSearch: value });
  };

  handleStatus = (status: any) => {
    this.setState({ statusSearch: status });
  };

  handleSearch = (e: any) => {
    this.fetch();
  };

  handleClear = async (e: any) => {
    await this.setState({
      dateSearch: [],
      nameSearch: undefined,
      statusSearch: undefined,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    });
    this.fetch();
  };

  handleCreate = (e: any) => {
    this.props.history.push("/tracking-url/create");
  };

  handleEditTrackingUrl = (record: any) => {
    this.props.history.push(`/tracking-url/${record.id}`);
  };

  handleDeleteTrackingUrl = async (record: any) => {
    const vm = this;
    const dataTable: any = this.state.dataTable;
    confirm({
      title: "Do you want to delete these items?",
      content: `${record.name} will delete`,
      async onOk() {
        const result = await vm.trackingUrlService.delete(record.id);
        if (result && result.delete) {
          dataTable.splice(dataTable.indexOf(record), 1);
          await vm.setState({ dataTable });
          await vm.fetch();
          message.success("deleted!");
          await vm.userService.saveActivity({
            moduleName: "tracking url",
            actionName: "delete",
            contentId: record.id,
          });
          return result.delete;
        }
      },
      onCancel() {},
    });
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({ pagination: pager });
    if (sorter.field) {
      this.fetch({
        page: pager.current,
        limit: pager.pageSize,
        sorting: sorter.field,
        order: sorter.order,
      });
    } else {
      this.fetch({ page: pager.current, limit: pager.pageSize });
    }
  };

  fetch = async (params: any = {}) => {
    if (this.state.dateSearch.length) {
      let d1 = moment(this.state.dateSearch[0]).format("YYYY-MM-DD");
      let d2 = moment(this.state.dateSearch[1]).format("YYYY-MM-DD");
      params.updated_date = `${d1},${d2}`;
    }
    if (this.state.nameSearch) {
      params.name = this.state.nameSearch;
    }
    if (this.state.linkSearch) {
      params.link = this.state.linkSearch;
    }
    if (this.state.statusSearch) {
      params.status = this.state.statusSearch === "active" ? "A" : "D";
    }
    this.setState({ loading: true });
    let dataTable: any = this.state.dataTable;
    const pagination = { ...this.state.pagination };
    const result = await this.trackingUrlService.retrieveTrackingUrls(params);
    if (result) {
      if (result[0].length > 0) dataTable = result[0];
      else dataTable = [];
      pagination.total = result[1];
      this.setState({
        dataTable,
        pagination,
      });
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="tracking-url">
        <div className="input-filter">
          <div className="input-item">
            <span className="text">Date</span>
            <RangePicker
              className="input"
              onChange={this.handleDatepicker}
              value={this.state.dateSearch}
              format="YYYY-MM-DD"
              data-at="field_date"
            />
          </div>
          <div className="input-item">
            <span className="text">Name</span>
            <Input
              placeholder="Name"
              className="input"
              onChange={this.handleName}
              value={this.state.nameSearch}
              onKeyUp={(e) => (e.keyCode === 13 ? this.handleSearch(e) : "")}
              data-at="field_name"
            />
          </div>
          <div className="input-item">
            <span className="text">Link</span>
            <Input
              placeholder="Link"
              className="input"
              onChange={this.handleLink}
              value={this.state.linkSearch}
              onKeyUp={(e) => (e.keyCode === 13 ? this.handleSearch(e) : "")}
              data-at="field_link"
            />
          </div>
          <div className="input-item short">
            <span className="text">Status</span>
            <Select
              className="input"
              placeholder="All"
              onChange={this.handleStatus}
              value={this.state.statusSearch}
              data-at="field_status"
            >
              <Option data-at="status_option-all" value="all">
                All
              </Option>
              <Option data-at="status_option-active" value="active">
                Active
              </Option>
              <Option data-at="status_option-inactive" value="inactive">
                Inactive
              </Option>
            </Select>
          </div>
          <div className="input-item button">
            <span className="text"></span>
            <div className="btn">
              <Button
                type="primary"
                icon="search"
                onClick={this.handleSearch}
                data-at="btn_search"
              >
                Search
              </Button>
              <Button
                type="default"
                onClick={this.handleClear}
                icon="sync"
                data-at="btn-clear"
                className="btn-custom-export"
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
        <div className="export-bar">
          <div className="create">
            <Button
              data-at="btn-create"
              type="default"
              icon="plus-circle"
              className="btn-custom-darkgreen"
              onClick={this.handleCreate}
            >
              Create
            </Button>
          </div>
        </div>
        <div className="card-table">
          <Table
            columns={this.columns}
            rowKey={(record: any) => record.id}
            pagination={this.state.pagination}
            loading={this.state.loading}
            dataSource={this.state.dataTable}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default TrackingUrl;
