import React, { Component } from 'react';
import { Radio, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import layout1 from '../richmenu-image/layout1.png';
import layout2 from '../richmenu-image/layout2.png';
import layout3 from '../richmenu-image/layout3.png';
import layout4 from '../richmenu-image/layout4.png';
import layout5 from '../richmenu-image/layout5.png';
import layout6 from '../richmenu-image/layout6.png';

class LayoutSelection extends Component<any & FormComponentProps>  {
  state = {
    layoutSelected: 0
  }

  constructor(props: any & FormComponentProps) {
    super(props);
    const value = props.value || {};
    this.state = {
      layoutSelected: value.layoutSelected || 0
    }
  }

  static getDerivedStateFromProps(nextProps: any) {
    if ('value' in nextProps) {
      return nextProps.value.layoutSelected || 0;
    }
    return null;
  }

  handleChange = (e: any) => {
    const layoutSelected = parseInt(e.target.value || 0, 10);
    if(isNaN(layoutSelected)) {
      return;
    }
    if (!('value' in this.props)) {
      this.setState({ layoutSelected });
    }
    this.triggerChange(layoutSelected);
  }

  triggerChange = (changedValue: any) => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue); 
    }
  };

  handleLayoutClick = (index: number) => {
    const layout = document.getElementById('r_layout_' + (index+1)) as HTMLElement;
    layout.click();
  }

  render() {
    return <Radio.Group className="richmenu-layout" onChange={this.handleChange} defaultValue={this.props.value}>
      <div className="richmenu" onClick={() => this.handleLayoutClick(0)}>
        <img src={layout1} alt="" />
        <Radio data-at="radio_layout-1" id="r_layout_1" value={0}></Radio>
      </div>
      <div className="richmenu" onClick={() => this.handleLayoutClick(1)}>
        <img src={layout2} alt="" />
        <Radio data-at="radio_layout-2" id="r_layout_2" value={1}></Radio>
      </div>
      <div className="richmenu" onClick={() => this.handleLayoutClick(2)}>
        <img src={layout3} alt="" />
        <Radio data-at="radio_layout-3" id="r_layout_3" value={2}></Radio>
      </div>
      <div className="richmenu" onClick={() => this.handleLayoutClick(3)}>
        <img src={layout4} alt="" />
        <Radio data-at="radio_layout-4" id="r_layout_4" value={3}></Radio>
      </div>
      <div className="richmenu" onClick={() => this.handleLayoutClick(4)}>
        <img src={layout5} alt="" />
        <Radio data-at="radio_layout-5" id="r_layout_5" value={4}></Radio>
      </div>
      <div className="richmenu" onClick={() => this.handleLayoutClick(5)}>
        <img src={layout6} alt="" />
        <Radio data-at="radio_layout-6" id="r_layout_6" value={5}></Radio>
      </div>
    </Radio.Group>
  }
}

export default Form.create<any>()(LayoutSelection);

