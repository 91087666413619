import React, { Component } from "react";
import {
  DatePicker,
  Input,
  Button,
  Table,
  Select,
  Modal,
  message,
  Spin,
} from "antd";
import ProductService from "../../services/Product";
import moment from "moment";
import "./Product.css";
import UsersService from "../../services/Users";

const { Option } = Select;
const { confirm } = Modal;
const { RangePicker } = DatePicker;

class Product extends Component<any, any> {
  productService = new ProductService();
  userService = new UsersService();
  columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: true,
      render: (text: any) => {
        return <span data-at={"label_id-" + text}>{text}</span>;
      },
    },
    {
      title: "Modified Date",
      dataIndex: "updated_date",
      key: "updated_date",
      render: (text: any, record: any) => {
        const date = moment(record.updated_date).format("YYYY-MM-DD HH:mm");
        return <span data-at={"label_date-" + record.id}>{date}</span>;
      },
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text: any, record: any) => {
        return (
          <span
            data-at={"label_name-" + record.id}
            style={{ fontSize: "16px", fontFamily: "Kalatexa" }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => {
        const status = text.charAt(0).toUpperCase() + text.slice(1);
        return <span data-at={"label_status-" + record.id}>{status}</span>;
      },
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <div className="btn-row-product">
          <Button
            ghost
            type="primary"
            icon="edit"
            size="small"
            onClick={() => this.handleEditProduct(record)}
            data-at={"btn_edit-" + record.id}
            className="btn-custom-blue"
          >
            Edit
          </Button>
          <Button
            ghost
            type="danger"
            icon="delete"
            size="small"
            onClick={() => this.handleDeleteProduct(record)}
            data-at={"btn_delete-" + record.id}
            className="btn-custom-red"
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  state = {
    dateSearch: [],
    nameSearch: undefined,
    statusSearch: undefined,
    dataTable: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    loading: false,
    deleting: false,
    exporting: false,
    role: undefined,
  };

  componentWillMount() {
    document.title = "LINE x MTL - CMS Product";
    const userRole = window.localStorage.getItem("role");
    this.setState({ role: userRole });
  }

  componentDidMount() {
    this.fetch();
    this.userService.saveActivity({
      moduleName: "product",
      actionName: "view",
    });
  }

  componentWillUnmount() {
    delete this.productService;
  }

  handleDatepicker = (dateMoment: any) => {
    this.setState({ dateSearch: dateMoment });
  };

  handleName = (e: any) => {
    const value = e.target.value;
    this.setState({ nameSearch: value });
  };

  handleStatus = (status: any) => {
    this.setState({ statusSearch: status });
  };

  handleSearch = (e: any) => {
    this.fetch();
  };

  handleClear = async (e: any) => {
    await this.setState({
      dateSearch: [],
      nameSearch: undefined,
      statusSearch: undefined,
      dataTable: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
    });
    this.fetch();
  };

  handleExport = async (e: any) => {
    this.setState({ exporting: true });
    let params: any = {};
    if (this.state.dateSearch.length) {
      let d1 = moment(this.state.dateSearch[0]).format("YYYY-MM-DD");
      let d2 = moment(this.state.dateSearch[1]).format("YYYY-MM-DD");
      params.updated_date = `${d1},${d2}`;
    }
    if (this.state.nameSearch) {
      params.name = this.state.nameSearch;
    }
    if (this.state.statusSearch) {
      params.status = this.state.statusSearch;
    }
    await this.productService.retrieveProductsCSV(params);
    this.setState({ exporting: false });
    this.userService.saveActivity({
      moduleName: "product",
      actionName: "export",
    });
  };

  handleCreate = () => {
    this.props.history.push("/product-category/product/create");
  };

  handleEditProduct = (record: any) => {
    this.props.history.push(`/product-category/product/${record.id}`);
  };

  handleDeleteProduct = async (record: any) => {
    const vm = this;
    const dataTable: any = this.state.dataTable;
    this.setState({ deleting: true });
    const used = await this.productService.getUsedProduct(record.id);
    this.setState({ deleting: false });
    if (used.amount > 0) {
      Modal.error({
        title: `Can't Delete this product`,
        content: (
          <span>
            Product is used in carousel&nbsp;
            <a
              href={`/product-category/carousel/${used.id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontFamily: "Kalatexa, Ubuntu",
                fontWeight: "normal",
                textDecoration: "underline",
                fontSize: "16px",
              }}
            >
              {used.name}
            </a>
          </span>
        ),
      });
    } else {
      confirm({
        title: "Do you want to delete these items?",
        content: `${record.name} will delete`,
        onOk: async () => {
          const result = await vm.productService.delete(record.id);
          if (result && result.delete) {
            dataTable.splice(dataTable.indexOf(record), 1);
            await vm.setState({ dataTable });
            message.success("deleted!");
            vm.userService.saveActivity({
              moduleName: "product",
              actionName: "delete",
              contentId: record.id,
            });
            return result.delete;
          }
        },
      });
    }
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({ pagination: pager });
    if (sorter.field) {
      this.fetch({
        page: pager.current,
        limit: pager.pageSize,
        sorting: sorter.field,
        order: sorter.order,
      });
    } else {
      this.fetch({ page: pager.current, limit: pager.pageSize });
    }
  };

  fetch = async (params: any = {}) => {
    if (this.state.dateSearch.length) {
      let d1 = moment(this.state.dateSearch[0]).format("YYYY-MM-DD");
      let d2 = moment(this.state.dateSearch[1]).format("YYYY-MM-DD");
      params.updated_date = `${d1},${d2}`;
    }
    if (this.state.nameSearch) {
      params.name = this.state.nameSearch;
    }
    if (this.state.statusSearch) {
      params.status = this.state.statusSearch;
    }
    this.setState({ loading: true });
    let dataTable: any = this.state.dataTable;
    const pagination = { ...this.state.pagination };
    const result = await this.productService.retrieveProducts(params);
    if (result) {
      if (result[0].length > 0) dataTable = result[0];
      else dataTable = [];
      pagination.total = result[1];
      this.setState({
        dataTable,
        pagination,
      });
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="product-list">
        <div className="input-filter">
          <div className="input-item">
            <span className="text">Date</span>
            <RangePicker
              className="input"
              onChange={this.handleDatepicker}
              value={this.state.dateSearch}
              data-at="field_date"
            />
          </div>
          <div className="input-item">
            <span className="text">Name</span>
            <Input
              placeholder="Name"
              className="input"
              onChange={this.handleName}
              value={this.state.nameSearch}
              onKeyUp={(e) => (e.keyCode === 13 ? this.handleSearch(e) : "")}
              data-at="field_name"
            />
          </div>
          <div className="input-item short">
            <span className="text">Status</span>
            <Select
              className="input"
              placeholder="All"
              onChange={this.handleStatus}
              value={this.state.statusSearch}
              data-at="field_status"
            >
              <Option data-at="status_option-all" value="all">
                All
              </Option>
              <Option data-at="status_option-active" value="active">
                Active
              </Option>
              <Option data-at="status_option-inactive" value="inactive">
                Inactive
              </Option>
            </Select>
          </div>
          <div className="input-item button">
            <span className="text"></span>
            <div className="btn">
              <Button
                type="primary"
                icon="search"
                onClick={this.handleSearch}
                data-at="btn_search"
              >
                Search
              </Button>
              <Button
                type="default"
                onClick={this.handleClear}
                icon="sync"
                data-at="btn_clear"
                className="btn-custom-export"
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
        <div className="export-bar">
          <div className="create">
            <Button
              type="default"
              icon="plus-circle"
              onClick={this.handleCreate}
              className="btn-custom-darkgreen"
              data-at="btn_create"
            >
              Create
            </Button>
          </div>
          <Button
            type="default"
            icon="download"
            onClick={this.handleExport}
            loading={this.state.exporting}
            disabled={this.state.exporting}
            data-at="btn_export"
            className="btn-custom-export"
            style={{ display: this.state.role !== "admin" ? "none" : "" }}
          >
            Export
          </Button>
        </div>
        <div className="card-table">
          <Spin spinning={this.state.deleting} delay={200}>
            <Table
              columns={this.columns}
              rowKey={(record: any) => record.id}
              pagination={this.state.pagination}
              loading={this.state.loading}
              dataSource={this.state.dataTable}
              onChange={this.handleTableChange}
            />
          </Spin>
        </div>
      </div>
    );
  }
}

export default Product;
