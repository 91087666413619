import React from 'react';
import { Icon } from 'antd';

const tableLayout = (index: any) => {
  switch (index) {
    case 0:
      return <React.Fragment>
        <div className="row">
          <div className="col"><p>A</p></div>
          <div className="col"><p>B</p></div>
          <div className="col"><p>C</p></div>
        </div>
        <div className="row">
          <div className="col"><p>D</p></div>
          <div className="col"><p>E</p></div>
          <div className="col"><p>F</p></div>
        </div>
      </React.Fragment>
    case 1:
      return <React.Fragment>
        <div className="row">
          <div className="col"><p>A</p></div>
          <div className="col"><p>B</p></div>
        </div>
        <div className="row">
          <div className="col"><p>C</p></div>
          <div className="col"><p>D</p></div>
        </div>
      </React.Fragment>
    case 2:
      return <React.Fragment>
        <div className="row">
          <div className="col"><p>A</p></div>
          <div className="col"><p>B</p></div>
        </div>
        <div className="row">
          <div className="col"><p>C</p></div>
          <div className="col"><p>D</p></div>
          <div className="col"><p>E</p></div>
        </div>
      </React.Fragment>
    case 3:
      return <React.Fragment>
        <div className="row">
          <div className="col"><p>A</p></div>
          <div className="col"><p>B</p></div>
          <div className="col"><p>C</p></div>
        </div>
        <div className="row">
          <div className="col"><p>D</p></div>
          <div className="col"><p>E</p></div>
        </div>
      </React.Fragment>
    case 4:
      return <React.Fragment>
        <div className="row compact">
          <div className="col"><p>A</p></div>
          <div className="col"><p>B</p></div>
          <div className="col"><p>C</p></div>
        </div>
      </React.Fragment>
    case 5:
      return <React.Fragment>
        <div className="row compact">
          <div className="col"><p>A</p></div>
          <div className="col"><p>B</p></div>
        </div>
      </React.Fragment>
    default:
      return <React.Fragment>
        <div className="row">
          <div className="col"><p>A</p></div>
          <div className="col"><p>B</p></div>
          <div className="col"><p>C</p></div>
        </div>
        <div className="row">
          <div className="col"><p>D</p></div>
          <div className="col"><p>E</p></div>
          <div className="col"><p>F</p></div>
        </div>
      </React.Fragment>;
  }
}

export const RichPreview = (props: any) => {
  return <React.Fragment>
    <div className={props.index > 3 ? "rich-preview compact": "rich-preview"} style={(props.showImage) ? { border: 'none' } : { border: '1.5px dashed #DDDDDD' }}>
      <img alt="Richmenu" id="richimage" className="image" src={(props.image)? props.image: null} style={(props.showImage) ? { display: 'block' } : { display: 'none' }} />
      <Icon type="file-image" className="icon" style={(props.showImage) ? { display: 'none' } : { display: 'block' }} />
      <div className="table-layout" style={(props.showImage) ? { display: 'block' } : { display: 'none' }}>
        {tableLayout(props.index)}
      </div>
    </div>
    <p>
      <strong>Choose design guide size </strong>
      <span>2500×1686, 1200×810, 800×540, 800×270</span>
    </p>
  </React.Fragment>
}