import React, { Component } from 'react'
import './AgentBacklistMonthly.css'
import { Table, Button } from 'antd'
import AgentBacklistService from '../../../services/AgentBacklist'
import UsersService from '../../../services/Users'

class AgentBacklistMonthly extends Component {
    agentBacklistService = new AgentBacklistService();
    userService = new UsersService()
    state = {
        loading: true,
        dataTable: [],
        page: 1,
        pagination: { 
            defaultPageSize: 10,
            total: 0,
            current: 1
        }

    }
    async componentDidMount() {
        const response = await this.agentBacklistService.getAgentBacklistMonthly(this.state.pagination.current)
        this.setState({
            dataTable: response.items,
            loading: false
        })
        this.userService.saveActivity({
            moduleName:'agent backlist',
            actionName: 'view monthly'
        })
    }
    handleTableChange = async (pagination: any) => {
        this.setState({loading: true})
        const response = await this.agentBacklistService.getAgentBacklistMonthly(this.state.pagination.current)
        this.setState({
            loading: false,
            dataTable: response.items,
            pagination: {
                ...this.state.pagination,
                total: response.count,
                current: pagination.current
            }
        })
    }
    handleDownloadClick = async (id: number) => {
        const response = await this.agentBacklistService.getAgentBacklistCsvFileMonthly(id)
        this.userService.saveActivity({
            moduleName:'agent backlist',
            actionName: 'download monthly',
            contentId: id
        })
        window.open(response)
    }
    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Date',
                dataIndex: 'createdDate',
                key: 'createdDate'
            },
            {
                title: 'Action',
                key: 'action',
                render: (text: any, record: any) => {
                  return <Button icon="download" onClick={()=>this.handleDownloadClick(record.id)}>Download</Button>
                },
            },
        ]
        return <div className="agent-backlist-monthly">
            <div className="card-table">
                <Table 
                    loading={this.state.loading}
                    columns={columns}
                    dataSource={this.state.dataTable}
                    onChange={this.handleTableChange}
                />
            </div>
        </div>
    }
}

export default AgentBacklistMonthly