import React, { Component } from "react";
import { Form, Switch, Button, Input, message, Spin } from "antd";
import ProductCategoryService from "../../../services/ProductCategory";
import "./ProductCategoryForm.css";
import UsersService from "../../../services/Users";

class ProductCategoryForm extends Component<any, any> {
  productCategoryService = new ProductCategoryService();
  userService = new UsersService();
  state = {
    mode: "create",
    productCategory: {
      name: undefined,
      keyword: undefined,
      status: "active",
    },
    updateLoading: false,
  };

  async componentWillMount() {
    if (this.props.match.url === "/product-category/create") {
      document.title = "LINE x MTL - CMS Product Category [Create]";
      this.setState({ mode: "create" });
    } else {
      this.setState({ updateLoading: true });
      document.title = "LINE x MTL - CMS Product Category [Edit]";
      const id = this.props.match.params.id;
      const productCategory =
        await this.productCategoryService.retrieveProductCategory(id);
      if (productCategory) {
        this.setState({ productCategory, mode: "update" });
      } else {
        this.setState({ updateLoading: false });
        this.props.history.replace("/product-category");
      }
      this.setState({ updateLoading: false });
      this.userService.saveActivity({
        moduleName: "product category",
        actionName: "detail",
        contentId: id,
      });
    }
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const productCategory: any = this.state.productCategory;
        const mode = this.state.mode;
        productCategory.name = values.name.trim();
        productCategory.keyword = values.keyword.trim();
        productCategory.status = values.status ? "active" : "inactive";

        if (mode === "update") {
          this.setState({ updateLoading: true });
          const result = await this.productCategoryService.update(
            this.state.productCategory
          );
          if (result && result.update) {
            message.success("update successful");
            setTimeout(() => {
              this.props.history.replace("/product-category");
            }, 1000);
          }
          this.setState({ updateLoading: false });
          this.userService.saveActivity({
            moduleName: "product category",
            actionName: "update",
            contentId: productCategory.id,
          });
        }

        if (mode === "create") {
          this.setState({ updateLoading: true });
          const result = await this.productCategoryService.create(
            this.state.productCategory
          );
          if (result && result.id) {
            this.userService.saveActivity({
              moduleName: "product category",
              actionName: "create",
              contentId: result.id,
            });
            message.success("create successful");
            setTimeout(() => {
              this.props.history.replace("/product-category");
            }, 1000);
          }
          this.setState({ updateLoading: false });
        }
      }
    });
  };

  checkBlank = (rule: any, value: any, callback: any) => {
    if (value.trim() === "" || value.trim().length === 0) {
      rule.message = "Do not use white space.";
      callback(false);
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return (
      <div className="product-category-form">
        <Spin spinning={this.state.updateLoading} delay={200}>
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            className="form"
          >
            <Form.Item label="Category Name">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your product category name",
                  },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.productCategory.name,
              })(
                <Input
                  data-at="field_name"
                  placeholder="Product category name"
                />
              )}
            </Form.Item>

            <Form.Item label="Keyword">
              {getFieldDecorator("keyword", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your product category keyword",
                  },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.productCategory.keyword,
              })(
                <Input
                  data-at="field_keyword"
                  placeholder="Keyword for line chat room"
                />
              )}
            </Form.Item>

            <Form.Item label="Status">
              {getFieldDecorator("status", {
                valuePropName: "checked",
                initialValue: this.state.productCategory.status === "active",
              })(
                <Switch
                  data-at="btn_status"
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              )}
            </Form.Item>

            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
              <Button
                data-at="btn_submit"
                icon="save"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
              <Button
                type="default"
                htmlType="button"
                style={{ marginLeft: "8px" }}
                onClick={() => this.props.history.goBack()}
                data-at="btn_back"
              >
                Back
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create({ name: "Product_Category" })(ProductCategoryForm);
