import React, { Component } from 'react'
import './Maintenance.css'
import { Form, Input, Button, Row, Col, Switch, Spin, message } from 'antd'
import MaintenanceService from '../../services/Maintenance'
import UsersService from '../../services/Users'

class Maintenance extends Component<any> {
    maintenanceService = new MaintenanceService()
    userService = new UsersService()
    state = {
        loading: true,
        maintenanceData: {
            text: null,
            status: 1
        }
    }
    async componentDidMount() {
        const maintenanceData: any = await this.maintenanceService.getMaintenance()
        this.setState({
            loading: false,
            maintenanceData: maintenanceData || {},
        })
        this.userService.saveActivity({
            moduleName:'maintenance',
            actionName: 'view'
        });
    }
    handleSubmit = (e: any) => {
        this.setState({
            loading: true
        })
        e.preventDefault();
        this.props.form.validateFields(async (err : any, values: any) => {
            if (!err) {
                values.status = values.status?1:2
                try {
                    await this.maintenanceService.saveMaintenance(values)
                    this.userService.saveActivity({
                        moduleName:'maintenance',
                        actionName: 'save'
                    })
                    message.success('Maintenace saved.')
                } catch (error) {
                    message.error(error)
                }
            }
            this.setState({loading: false})
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
              lg: { span: 4 }
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        return <Spin spinning={this.state.loading}>
            <div className="maintenance">
                <div className="card-table">
                    <Row>
                        <Col lg={12}>
                            <Form onSubmit={this.handleSubmit} layout="vertical" {...formItemLayout}>
                                <Form.Item label="Text">
                                    {getFieldDecorator('text',{
                                        rules: [{ required: true, message: 'Please input text!' }],
                                        initialValue: this.state.maintenanceData.text
                                    })(
                                        <Input placeholder="Text" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Status">
                                    {getFieldDecorator('status',{
                                        // rules: [{ required: true, message: 'Please input text!' }],
                                        valuePropName: "checked",
                                        initialValue: this.state.maintenanceData.status === 1
                                    })(
                                        <Switch checkedChildren="Enable" unCheckedChildren="Disable" />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        icon="save"
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        </Spin>
    }
}

export default Form.create({ name: 'maintenance' })(Maintenance)