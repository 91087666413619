import moment from 'moment';
import RequestService from './HttpRequestService';

class DashboardService {
  requestService: RequestService = new RequestService();

  async clearCache() {
    return this.requestService.get('/customers/clear-cache');
  }

  async retrieveCustomersAmount() {
    return this.requestService.get('/customers/amount');
  }

  async retrieveMessageAmount() {
    return this.requestService.get('/message/amount');
  }

  async retrieveCustomersMonthly(dates: any, diff_day: number) {
    const result = await this.requestService.get('/customers/amount/monthly', { dates });
    const field = new Array(diff_day);
    const data = new Array(diff_day);
    if (result) {
      for (let i = 0; i < diff_day; i++) {
        if (result[i]) {
          field[i] = moment(result[i].created_date).format('YYYY-MM-DD');
          data[i] = result[i].amount;
        } else {
          field[i] = '';
          data[i] = 0;
        }
      }
    }
    return { field, data };
  }

  async retrieveMessagesMonthly(dates: any, diff_day: number) {
    const result = await this.requestService.get('/message/amount/monthly', { dates });
    const field = new Array(diff_day);
    const data = new Array(diff_day);
    if (result) {
      for (let i = 0; i < diff_day; i++) {
        if (result[i]) {
          field[i] = moment(result[i].created_date).format('YYYY-MM-DD');
          data[i] = result[i].amount;
        } else {
          field[i] = '';
          data[i] = 0;
        }
      }
    }
    return { field, data };
  }
}

export default DashboardService;