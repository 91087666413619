import React, { Component } from 'react';
import { Icon, DatePicker, Button } from 'antd';
import { Line } from 'react-chartjs-2'
import moment from 'moment';
import DashboardService from '../../services/Dashboard';
import './Dashboard.css';
import UsersService from '../../services/Users'

const { RangePicker } = DatePicker;

class Dashboard extends Component<any, any> {
  dashboardService = new DashboardService();
  userService = new UsersService()
  timer: any;

  state = {
    msgChart: {
      labels: [''],
      datasets: [
        {
          label: 'Message Monthly',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgb(47, 40, 112)',
          borderColor: 'rgb(0, 142, 207)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgb(47, 40, 112)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 3,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgb(47, 40, 112)',
          pointHoverBorderColor: 'rgb(0, 142, 207)',
          pointHoverBorderWidth: 4,
          pointRadius: 2,
          pointHitRadius: 10,
          data: ['']
        }
      ]
    },
    customerChart: {
      labels: [''],
      datasets: [
        {
          label: 'Customer Monthly',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgb(0, 96, 46)',
          borderColor: 'rgb(112, 187, 111)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgb(0, 96, 46)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 3,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgb(0, 96, 46)',
          pointHoverBorderColor: 'rgb(112, 187, 111)',
          pointHoverBorderWidth: 4,
          pointRadius: 2,
          pointHitRadius: 10,
          data: ['']
        }
      ]
    },
    customerAmount: 0,
    messageAmount: 0,
    customerDate: [],
    messageDate: [],
    customerDiff: 15,
    messageDiff: 15,
  }

  async componentWillMount() {
    document.title = "LINE x MTL - CMS Dashboard";
    await this.setState({
      customerDate: [moment().subtract(14, 'days'), moment()],
      messageDate: [moment().subtract(14, 'days'), moment()]
    });
    this.fetchCustomerAmount();
    this.fetchMessageAmount();
    this.fetchCustomerMonthly();
    this.fetchMessageMonthly();

    this.timer = setInterval(() => {
      this.fetchCustomerAmount();
      this.fetchMessageAmount();
    }, 20 * 10000)
    this.userService.saveActivity({
      moduleName:'dashboard',
      actionName: 'view'
    })
  }

  async componentWillUnmount() {
    clearInterval(this.timer);
  }

  disableDate = (current: any) => {
    return current && current > moment().endOf('day');
  }

  customerChange = (dates: any) => {
    if (dates.length === 2) {
      const diff_day = dates[1].diff(dates[0], 'days');
      // if (diff_day > 14) {
      //   dates[1] = dates[1].subtract(diff_day - 14, 'days');
      //   message.warn('limit range 15 days!')
      // }
      this.setState({customerDate: dates, customerDiff: diff_day});
    }
  }

  messageChange = (dates: any) => {
    if (dates.length === 2) {
      const diff_day = dates[1].diff(dates[0], 'days');
      // if (diff_day > 14) {
      //   dates[1] = dates[1].subtract(diff_day - 14, 'days');
      //   message.warn('limit range 15 days!')
      // }
      this.setState({messageDate: dates, messageDiff: diff_day});
    }
  }

  fetchCustomerAmount = async () => {
    const customerAmount = await this.dashboardService.retrieveCustomersAmount();
    this.setState({ customerAmount });
  }

  fetchMessageAmount = async () => {
    const messageAmount = await this.dashboardService.retrieveMessageAmount();
    this.setState({ messageAmount });
  }

  fetchCustomerMonthly = async () => {
    const d1 = moment(this.state.customerDate[0]).format('YYYY-MM-DD');
    const d2 = moment(this.state.customerDate[1]).format('YYYY-MM-DD');
    const dates = `${d1},${d2}`;
    const responseCustomer = await this.dashboardService.retrieveCustomersMonthly(dates, this.state.customerDiff);
    const customerChart = this.state.customerChart;
    if (responseCustomer) {
      customerChart.datasets[0].data = responseCustomer.data;
      customerChart.labels = responseCustomer.field;
    }
    this.setState({ customerChart });
  }

  fetchMessageMonthly = async () => {
    const d1 = moment(this.state.messageDate[0]).format('YYYY-MM-DD');
    const d2 = moment(this.state.messageDate[1]).format('YYYY-MM-DD');
    const dates = `${d1},${d2}`;
    const responseMessage = await this.dashboardService.retrieveMessagesMonthly(dates, this.state.messageDiff);
    const msgChart = this.state.msgChart;
    if (responseMessage) {
      msgChart.datasets[0].data = responseMessage.data;
      msgChart.labels = responseMessage.field;
    }
    this.setState({ msgChart });
  }

  render() {
    return <div className="dashboard">
      <div className="row">
        <div className="card">
          <div className="card-col">
            <Icon type="team" style={{ color: 'rgb(114, 187, 111)' }} />
          </div>
          <div className="card-col">
            <div>
              <p>Customer</p>
              <p data-at="label-customer-amount">{this.state.customerAmount}</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-col">
            <Icon type="mail" style={{ color: 'rgb(0, 142, 207)' }} />
          </div>
          <div className="card-col">
            <div>
              <p>Message</p>
              <p  data-at="label-message-amount">{this.state.messageAmount}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row-chart">
        <div className="chart-item">
          <div className="search">
            <RangePicker 
              disabledDate={this.disableDate}
              onCalendarChange={this.customerChange}
              value={this.state.customerDate}
              data-at="field-customer-date"
              className="input-item"
            />
            <Button 
              icon="search" 
              type="primary"
              onClick={() => this.fetchCustomerMonthly()}
              data-at="btn-customer"
            >Search</Button>
          </div>
          <div className="chart">
            <Line data={this.state.customerChart}
              options={{ maintainAspectRatio: false,  scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }}}
            />
          </div>
        </div>
        <div className="chart-item" >
          <div className="search">
            <RangePicker
             disabledDate={this.disableDate}
             onCalendarChange={this.messageChange}
             value={this.state.messageDate}
             data-at="field-message-date"
             className="input-item"
            />
            <Button 
              icon="search"
              type="primary"
              onClick={() => this.fetchMessageMonthly()}
              data-at="btn-message"
            >Search</Button>
          </div>
          <div className="chart">
            <Line data={this.state.msgChart}
              options={{ maintainAspectRatio: false,  scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            } }}
            />
          </div>
        </div>
      </div>
    </div>
  }
}

export default Dashboard;