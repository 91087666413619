import RequestService from './HttpRequestService';
import fileDownload from 'js-file-download';

class Privilege {
  requestService = new RequestService();

  async createPrivilege(params: any = {}) {
    const formData = new FormData();
    formData.append('name', params.name);
    formData.append('linkUrl', params.linkUrl);
    formData.append('status', params.status);
    formData.append('file', params.image.file);
    return this.requestService.post('/privilege', formData, true);  
  }

  async update(privilege: any = {}) {
    const id = privilege.id;
    const formData = new FormData();
    formData.append('name', privilege.name);
    formData.append('linkUrl', privilege.linkUrl);
    formData.append('status', privilege.status);
    if (privilege.image) {
      formData.append('file', privilege.image.file);
    }
    return this.requestService.put(`/privilege/${id}`, formData, true);
  }

  async retrievePrivileges(params: any = {}) {
    return this.requestService.get('/privilege', params);
  }

  async retrievePrivilege(id: any) {
    const data = await this.requestService.get(`/privilege/${id}`);
    const image = await this.requestService.getImage(data.imgUrl);
    if (image) {
      data.image = 'data:image/png;base64, ' + new Buffer(image, 'binary').toString('base64');
    }
    return data;
  }

  async retrievePrivilegesCSV(params: any = {}) {
    const result = await this.requestService.get('/privilege/csv', params);
    const blob = new Blob(["\ufeff", result]);
    fileDownload(blob, 'privilege.csv');
  }

  async delete(id: any) {
    return this.requestService.delete('/privilege', id);
  }

  async checkPrivileges(id: any) {
    return this.requestService.get(`/privilege/check/${id}`);
  }
}

export default Privilege;
