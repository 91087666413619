import React, { Component } from "react";
import { Input, Button, Table, Select, Modal, message } from "antd";
import UsersService from "../../services/Users";
import jwtDecode from "jwt-decode";

import "./Users.css";

const { Option } = Select;
const { confirm } = Modal;

class Users extends Component<any, any> {
  userService = new UsersService();

  state = {
    usernameSearch: undefined,
    firstnameSearch: undefined,
    lastnameSearch: undefined,
    roleSearch: undefined,
    statusSearch: undefined,
    dataTable: [],
    columns: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    loading: false,
    exporting: false,
  };

  componentWillMount() {
    document.title = "LINE x MTL - CMS User Management";
    const refreshToken = localStorage.getItem("refresh_token");
    let username: any = null;
    if (refreshToken) {
      const payload: any = jwtDecode(refreshToken);
      username = payload.username;
    }
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: "120px",
        sorter: true,
        render: (text: any) => {
          return <span data-at={"label_id-" + text}>{text}</span>;
        },
      },
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
        sorter: true,
        render: (text: any, record: any) => {
          return <span data-at={"label_username-" + record.id}>{text}</span>;
        },
      },
      {
        title: "Firstname",
        dataIndex: "firstname",
        key: "firstname",
        sorter: true,
        render: (text: any, record: any) => {
          return (
            <span
              data-at={"label_firstname-" + record.id}
              style={{ fontSize: "16px", fontFamily: "Kalatexa" }}
            >
              {text}
            </span>
          );
        },
      },
      {
        title: "Lastname",
        dataIndex: "lastname",
        key: "lastname",
        sorter: true,
        render: (text: any, record: any) => {
          return (
            <span
              data-at={"label_lastname-" + record.id}
              style={{ fontSize: "16px", fontFamily: "Kalatexa" }}
            >
              {text}
            </span>
          );
        },
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        render: (text: any, record: any) => {
          if (text === "callcenter") {
            return <span data-at={"label_role-" + record.id}>Call Center</span>;
          } else {
            const role = text.charAt(0).toUpperCase() + text.slice(1);
            return <span data-at={"label_role-" + record.id}>{role}</span>;
          }
        },
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "120px",
        render: (text: any, record: any) => {
          const status = text.charAt(0).toUpperCase() + text.slice(1);
          return <span data-at={"label_status-" + record.id}>{status}</span>;
        },
        sorter: true,
      },
      {
        title: "Action",
        key: "action",
        render: (text: any, record: any) => (
          <div className="btn-row-user">
            <Button
              ghost
              type="primary"
              icon="edit"
              size="small"
              onClick={() => this.handleEditUser(record)}
              data-at={"btn_edit-" + record.id}
              className="btn-custom-blue"
            >
              Edit
            </Button>
            {record.username !== username ? (
              <Button
                ghost
                type="danger"
                icon="delete"
                size="small"
                onClick={() => this.handleDeleteUser(record)}
                data-at={"btn_delete-" + record.id}
                className="btn-custom-red"
              >
                Delete
              </Button>
            ) : null}
          </div>
        ),
      },
    ];
    this.setState({ columns });
    this.userService.saveActivity({
      moduleName: "User Management",
      actionName: "view",
    });
  }

  componentDidMount() {
    this.fetch();
  }

  componentWillUnmount() {
    delete this.userService;
  }

  handleStatus = (status: any) => {
    this.setState({ statusSearch: status });
  };

  handleRole = (role: any) => {
    this.setState({ roleSearch: role });
  };

  handleFirstname = (e: any) => {
    const firstname = e.target.value;
    this.setState({ firstnameSearch: firstname });
  };

  handleLastname = (e: any) => {
    const lastname = e.target.value;
    this.setState({ lastnameSearch: lastname });
  };

  handleUsername = (e: any) => {
    const username = e.target.value;
    this.setState({ usernameSearch: username });
  };

  handleSearch = (e: any) => {
    this.fetch();
  };

  handleClear = async (e: any) => {
    await this.setState({
      usernameSearch: undefined,
      firstnameSearch: undefined,
      lastnameSearch: undefined,
      roleSearch: undefined,
      statusSearch: undefined,
      dataTable: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
    });
    this.fetch();
  };

  handleExport = async (e: any) => {
    this.setState({ exporting: true });
    let params: any = {};
    params.username = this.state.usernameSearch;

    if (this.state.firstnameSearch) {
      params.firstname = this.state.firstnameSearch;
    }
    if (this.state.lastnameSearch) {
      params.lastname = this.state.lastnameSearch;
    }
    if (this.state.roleSearch) {
      params.role = this.state.roleSearch;
    }
    if (this.state.statusSearch) {
      params.status = this.state.statusSearch;
    }
    await this.userService.retrieveUsersCSV(params);
    this.setState({ exporting: false });
    this.userService.saveActivity({
      moduleName: "User Management",
      actionName: "export",
    });
  };

  handleCreate = () => {
    this.props.history.push("/users/create");
  };

  handleEditUser = (record: any) => {
    this.props.history.push(`/users/${record.id}`);
  };

  handleDeleteUser = async (record: any) => {
    const vm = this;
    const dataTable: any = this.state.dataTable;
    confirm({
      title: "Do you want to delete these items?",
      content: `${record.username} will delete`,
      async onOk() {
        const result = await vm.userService.delete(record.id);
        if (result && result.delete) {
          dataTable.splice(dataTable.indexOf(record), 1);
          await vm.setState({ dataTable });
          message.success("deleted!");
          vm.userService.saveActivity({
            moduleName: "User Management",
            actionName: "delete",
          });
          return result.delete;
        }
      },
    });
  };

  handleTableChange = async (pagination: any, filters: any, sorter: any) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({ pagination: pager });
    if (sorter.field) {
      this.fetch({
        page: pager.current,
        limit: pager.pageSize,
        sorting: sorter.field,
        order: sorter.order,
      });
    } else {
      this.fetch({ page: pager.current, limit: pager.pageSize });
    }
  };

  fetch = async (params: any = {}) => {
    let username: any = null;
    const refreshToken = localStorage.getItem("refresh_token");
    if (refreshToken) {
      const payload: any = jwtDecode(refreshToken);
      username = payload.username;
    }
    if (username) {
      params.excludeUsername = username;
    }
    if (this.state.usernameSearch) {
      params.username = this.state.usernameSearch;
    }
    if (this.state.firstnameSearch) {
      params.firstname = this.state.firstnameSearch;
    }
    if (this.state.lastnameSearch) {
      params.lastname = this.state.lastnameSearch;
    }
    if (this.state.roleSearch) {
      params.role = this.state.roleSearch;
    }
    if (this.state.statusSearch) {
      params.status = this.state.statusSearch;
    }
    this.setState({ loading: true });
    let dataTable: any = this.state.dataTable;
    const pagination = { ...this.state.pagination };
    const result = await this.userService.retrieveUsers(params);
    if (result) {
      if (result[0].length > 0) dataTable = result[0];
      else dataTable = [];
      pagination.total = result[1];
      this.setState({
        dataTable,
        pagination,
      });
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="user-list">
        <div className="input-filter">
          <div className="input-item short">
            <span className="text">Username</span>
            <Input
              placeholder="Username"
              className="input"
              onChange={this.handleUsername}
              value={this.state.usernameSearch}
              onKeyUp={(e) => (e.keyCode === 13 ? this.handleSearch(e) : "")}
              data-at="field_username"
            />
          </div>
          <div className="input-item">
            <span className="text">Firstname</span>
            <Input
              placeholder="Firstname"
              className="input"
              onChange={this.handleFirstname}
              value={this.state.firstnameSearch}
              onKeyUp={(e) => (e.keyCode === 13 ? this.handleSearch(e) : "")}
              data-at="field_firstname"
            />
          </div>
          <div className="input-item">
            <span className="text">Lastname</span>
            <Input
              placeholder="Lastname"
              className="input"
              onChange={this.handleLastname}
              value={this.state.lastnameSearch}
              onKeyUp={(e) => (e.keyCode === 13 ? this.handleSearch(e) : "")}
              data-at="field_lastname"
            />
          </div>
          <div className="input-item short">
            <span className="text">Role</span>
            <Select
              className="input"
              placeholder="All"
              onChange={this.handleRole}
              value={this.state.roleSearch}
              data-at="field_role"
            >
              <Option data-at="role_option-all" value="all">
                All
              </Option>
              <Option data-at="role_option-admin" value="admin">
                Admin
              </Option>
              <Option data-at="role_option-staff" value="staff">
                Staff
              </Option>
              <Option data-at="role_option-staff" value="callcenter">
                Call Center
              </Option>
            </Select>
          </div>
          <div className="input-item short">
            <span className="text">Status</span>
            <Select
              className="input"
              placeholder="All"
              onChange={this.handleStatus}
              value={this.state.statusSearch}
              data-at="field_status"
            >
              <Option data-at="status_option-all" value="all">
                All
              </Option>
              <Option data-at="status_option-active" value="active">
                Active
              </Option>
              <Option data-at="status_option-inactive" value="inactive">
                Inactive
              </Option>
            </Select>
          </div>
          <div className="input-item button">
            <span className="text"></span>
            <div className="btn">
              <Button
                type="primary"
                icon="search"
                onClick={this.handleSearch}
                data-at="btn_search"
              >
                Search
              </Button>
              <Button
                type="default"
                onClick={this.handleClear}
                icon="sync"
                data-at="btn_clear"
                className="btn-custom-export"
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
        <div className="export-bar">
          <div className="create">
            <Button
              type="default"
              icon="plus-circle"
              className="btn-custom-darkgreen"
              onClick={this.handleCreate}
              data-at="btn_create"
            >
              Create
            </Button>
          </div>
          <Button
            type="default"
            icon="download"
            onClick={this.handleExport}
            loading={this.state.exporting}
            disabled={this.state.exporting}
            data-at="btn_export"
            className="btn-custom-export"
          >
            Export
          </Button>
        </div>
        <div className="card-table">
          <Table
            columns={this.state.columns}
            rowKey={(record: any) => record.id}
            loading={this.state.loading}
            pagination={this.state.pagination}
            dataSource={this.state.dataTable}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default Users;
