import React, { Component } from "react";
import { Form, Button, Input, message, Checkbox, Spin } from "antd";
import { PasswordInput } from "antd-password-input-strength";
import zxcvbn from "zxcvbn";
import "./ProfileForm.css";
import UsersService from "../../../services/Users";

class ProfileForm extends Component<any, any> {
  userService = new UsersService();
  state = {
    mode: "create",
    user: {
      username: undefined,
      password: undefined,
      oldPassword: undefined,
      email: undefined,
      firstname: undefined,
      lastname: undefined,
      tel: undefined,
      role: undefined,
    },
    confirmDirty: false,
    newPassword: false,
    updateLoading: false,
    passwordLevel: (
      <span style={{ color: "#ff4033", fontWeight: "bold" }}>Weak</span>
    ),
  };

  handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  async componentWillMount() {
    this.setState({ mode: "update", updateLoding: true });
    try {
      this.setState({ updateLoading: true });
      document.title = "LINE x MTL - Profile";
      const id = "self";
      const user = await this.userService.retrieveUser(id);
      if (user) {
        this.userService.saveActivity({
          moduleName: "profile",
          actionName: "view",
        });
        this.setState({ user, mode: "update" });
      }
      this.setState({ mode: "update", updateLoading: false });
    } catch (err) {
      this.props.history.replace("/");
    }
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const mode = this.state.mode;
        const user = this.state.user;
        user.username = values.username;
        user.password = values.password;
        user.email = values.email;
        user.firstname = values.firstname;
        user.lastname = values.lastname;
        user.tel = values.tel;
        user.oldPassword = values.oldPassword;

        if (mode === "update") {
          this.setState({ updateLoading: true });
          const result = await this.userService.update(this.state.user);
          this.setState({ updateLoading: false });
          if (result && result.update) {
            this.userService.saveActivity({
              moduleName: "profile",
              actionName: "update",
            });
            message.success("update successful");
            if (this.state.newPassword) {
              setTimeout(() => {
                this.props.history.replace("/login?logout");
              }, 1300);
            }
          }
        }
      }
    });
  };

  compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  newPasswordHandle = (e: any) => {
    this.setState({ newPassword: e.target.checked });
  };

  validatePasswordFormat = (rule: any, value: any, callback: any) => {
    let hasNumber = false;
    let hasLower = false;
    let hasUpper = false;
    for (let i = 0; i < value.length; i++) {
      const character = value[i];
      if (character !== " " && !isNaN(Number(character))) {
        hasNumber = true;
      } else {
        if (character === character.toUpperCase()) {
          hasUpper = true;
        }
        if (character === character.toLowerCase()) {
          hasLower = true;
        }
      }
    }
    if (hasNumber && hasUpper && hasLower) {
      callback();
    } else {
      if (value.length > 7) {
        if (!hasUpper) {
          rule.message =
            "Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)";
          callback(false);
        } else if (!hasLower) {
          rule.message =
            "Your password must contain at least one lowercase letter.";
          callback(false);
        } else if (!hasNumber) {
          rule.message =
            "Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)";
          callback(false);
        }
      } else {
        callback();
      }
    }
  };

  sameUsername = (rule: any, value: any, callback: any) => {
    if (this.state.user.username === value) {
      rule.message = "Do not use the same username as the password.";
      callback(false);
    }
    callback();
  };

  handlePassword = (event: any) => {
    const score = zxcvbn(event.target.value).score;
    let passwordLevel = (
      <span style={{ color: "#ff4033", fontWeight: "bold" }}>Weak</span>
    );
    if (score === 0)
      passwordLevel = (
        <span style={{ color: "#ff4033", fontWeight: "bold" }}>Weak</span>
      );
    if (score === 1)
      passwordLevel = (
        <span style={{ color: "#fe940d", fontWeight: "bold" }}>Fair</span>
      );
    if (score === 2)
      passwordLevel = (
        <span style={{ color: "#ffd908", fontWeight: "bold" }}>Good</span>
      );
    if (score === 3)
      passwordLevel = (
        <span style={{ color: "#cbe11d", fontWeight: "bold" }}>Strong</span>
      );
    if (score === 4)
      passwordLevel = (
        <span style={{ color: "#6ecc3a", fontWeight: "bold" }}>
          Very strong
        </span>
      );
    this.setState({ passwordLevel });
  };

  checkBlank = (rule: any, value: any, callback: any) => {
    if (value.trim() === "" || value.trim().length === 0) {
      rule.message = "Do not use white space.";
      callback(false);
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const passwordComponent =
      this.state.mode === "create" ||
      (this.state.mode === "update" && this.state.newPassword) ? (
        <React.Fragment>
          <Form.Item label="Current password">
            {getFieldDecorator("oldPassword", {
              rules: [
                { required: true, message: "Please enter current password" },
              ],
            })(
              <Input.Password
                data-at="field_old_password"
                placeholder=""
                type="password"
                autoComplete="old-password"
              />
            )}
          </Form.Item>

          <Form.Item label="Password" className="password-level">
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please enter your password" },
                { validator: this.validateToNextPassword },
                { min: 8, message: "Password must be at least 8 characters" },
                { validator: this.validatePasswordFormat },
                { validator: this.sameUsername },
              ],
            })(
              <PasswordInput
                data-at="field_password"
                onChange={this.handlePassword}
                settings={{
                  height: 4,
                  colorScheme: {
                    levels: [
                      "#ff4033",
                      "#fe940d",
                      "#ffd908",
                      "#cbe11d",
                      "#6ecc3a",
                    ],
                    noLevel: "lightgrey",
                  },
                  alwaysVisible: true,
                }}
              />
            )}
          </Form.Item>

          <Form.Item label="Password strength">
            {this.state.passwordLevel}
          </Form.Item>

          <Form.Item label="Confirm Password">
            {getFieldDecorator("confirm", {
              rules: [
                {
                  required: true,
                  message: "Please enter your retype password",
                },
                { validator: this.compareToFirstPassword },
              ],
            })(
              <Input.Password
                data-at="field_confirm-password"
                placeholder=""
                type="password"
                onBlur={this.handleConfirmBlur}
                autoComplete="new-password"
              />
            )}
          </Form.Item>
        </React.Fragment>
      ) : null;

    const newPassword =
      this.state.mode === "update" ? (
        <Form.Item label="New Password">
          <Checkbox
            data-at="btn_newPassword"
            onChange={this.newPasswordHandle}
          ></Checkbox>
        </Form.Item>
      ) : null;

    return (
      <div className="user-form">
        <Spin spinning={this.state.updateLoading} delay={200}>
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            className="form"
          >
            <Form.Item label="Username">
              {getFieldDecorator("username", {
                rules: [
                  { required: true, message: "Please enter your username" },
                ],
                initialValue: this.state.user.username,
              })(
                <Input
                  data-at="field_username"
                  readOnly={this.state.mode === "update"}
                  placeholder="Username"
                  autoComplete="new-password"
                />
              )}
            </Form.Item>

            {newPassword}

            {passwordComponent}

            <Form.Item label="Email">
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "Please enter your email" },
                  { validator: this.checkBlank },
                  {
                    required: true,
                    pattern: new RegExp(
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ),
                    message: "Email invalid format",
                  },
                ],
                initialValue: this.state.user.email,
              })(<Input data-at="field_email" placeholder="Email" />)}
            </Form.Item>

            <Form.Item label="Firstname">
              {getFieldDecorator("firstname", {
                rules: [
                  { required: true, message: "Please enter your firstname" },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.user.firstname,
              })(<Input data-at="field_firstname" placeholder="Firstname" />)}
            </Form.Item>

            <Form.Item label="Lastname">
              {getFieldDecorator("lastname", {
                rules: [
                  { required: true, message: "Please enter your lastname" },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.user.lastname,
              })(<Input data-at="field_lastname" placeholder="Lastname" />)}
            </Form.Item>

            <Form.Item label="Phone Number">
              {getFieldDecorator("tel", {
                initialValue: this.state.user.tel,
              })(<Input data-at="field_phone" placeholder="Phone Number" />)}
            </Form.Item>

            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
              <Button data-at="btn_submit" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create({ name: "ProfileForm" })(ProfileForm);
