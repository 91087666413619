import React, { Component } from "react";
import { Button, Table, Icon, Input, message, Spin } from "antd";
import { DndProvider } from 'react-dnd';
import { DragableBodyRow } from './BodyRow'
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import ProductService from '../../../services/Product';
import ProductCategoryService from '../../../services/ProductCategory';
import moment from 'moment';
import './Carousel.css';
import UsersService from '../../../services/Users'

class CarouselManagement extends Component<any, any> {
  productService = new ProductService();
  productCategoryService = new ProductCategoryService();
  userService = new UsersService()
  columnsSource = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (text: any) => {
        return <span data-at={'label_id-' + text}>{text}</span>
      }
    },
    {
      title: 'Modified Date',
      dataIndex: 'updated_date',
      key: 'updated_date',
      render: (text: any, record: any) => {
        const date = moment(record.updated_date).format('YYYY-MM-DD HH:mm');
        return <span data-at={'label_date-' + record.id}>{date}</span>
      },
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text: any, record: any) => {
        return <span data-at={'label_name-' + record.id} style={{ fontSize: '16px', fontFamily: 'Kalatexa' }}>{text}</span>
      }
    },
    {
      title: '',
      render: (text: any, record: any) => (
        <Button type="link"
          data-at={'btn_add-' + record.id}
          onClick={() => this.handleAdd(record)}
          className="plus" >
          <Icon type="plus-circle" />
        </Button>
      ),
    }
  ]

  columnsCarousel = [
    {
      title: 'Order',
      key: 'no',
      render: (text: any, record: any) => (
        <span data-at={'label_order-' + record.id}><Icon type="menu" /></span>
      ),
    },
    {
      title: 'Modified Date',
      dataIndex: 'updated_date',
      key: 'updated_date',
      render: (text: any, record: any) => {
        const date = moment(record.updated_date).format('YYYY-MM-DD HH:mm');
        return <span data-at={'label_date-' + record.id}>{date}</span>
      },
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (text: any, record: any) => {
        return <span data-at={'label_name-' + record.id} style={{ fontSize: '16px', fontFamily: 'Kalatexa' }}>{text}</span>
      }
    },
    {
      title: '',
      key: 'remove',
      render: (text: any, record: any) => (
        <Button type="link"
          className="minus"
          onClick={() => this.handleRemove(record)}
          data-at={'btn_remove-' + record.id}
        >
          <Icon type="minus-circle" />
        </Button>
      ),
    }
  ]

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  state = {
    inputSearch: undefined,
    nameSearch: undefined,
    dataCarousel: [],
    dataSource: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    },
    loading: false,
    carouselLoading: false,
    categoryId: undefined,
    categoryName: null,
    updateLoading: false,
  };

  componentWillMount() {
    document.title = 'LINE x MTL - CMS Carousel';
    if (!this.props.match.params.id) {
      this.props.history.push('/product-category');
    }
    this.setState({ categoryId: this.props.match.params.id });
  }

  componentWillReceiveProps(newProps: any) {
    if (!newProps.match.params.id) {
      this.props.history.push('/product-category');
    }
  }

  async componentDidMount() {
    this.setState({ updateLoading: true });
    try {
      const category = await this.productCategoryService.retrieveProductCategory(this.state.categoryId);
      if (category) {
        this.setState({ categoryName: category.name, updateLoading: false });
        await this.fetchCarousel(this.state.categoryId);
        await this.fetch();
        this.userService.saveActivity({
          moduleName:'product category carousel',
          actionName: 'view',
          contentId: this.state.categoryId
        })
      } else {
        this.props.history.replace('/product-category');
      }
    } catch (err) {
      this.setState({ updateLoading: false });
      this.props.history.replace('/product-category');
    }
  }

  moveRow = (dragIndex: any, hoverIndex: any) => {
    const { dataCarousel } = this.state;
    const dragRow = dataCarousel[dragIndex];

    this.setState(
      update(this.state, {
        dataCarousel: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
    );
  };

  handleAdd = (record: any) => {
    let dataCarousel = Array(...this.state.dataCarousel);
    let dataSource = Array(...this.state.dataSource);
    if (dataCarousel.length >= 10) {
      message.warn('List full')
    } else {
      dataCarousel.push(record);
      dataSource.splice(dataSource.indexOf(record), 1);
      this.setState({ dataCarousel, dataSource });
      message.info(`${record.name} added!`);
    }
  }

  handleRemove = (record: any) => {
    let dataCarousel = Array(...this.state.dataCarousel);
    let dataSource = Array(...this.state.dataSource);
    dataSource.push(record);
    dataSource.sort((a, b) => a.id - b.id);
    dataCarousel.splice(dataCarousel.indexOf(record), 1);
    this.setState({ dataCarousel, dataSource });
  }

  handleSave = async (e: any) => {
    this.setState({ updateLoading: true });
    const result = await this.productCategoryService.saveCarousel(this.state.dataCarousel, this.state.categoryId);
    if (result) {
      message.success('save successful');
      this.userService.saveActivity({
        moduleName:'product category carousel',
        actionName: 'update',
        contentId: this.state.categoryId
      })
    } else {
      message.error('save failed');
    }
    this.setState({ updateLoading: false });
  }

  handleCancel = (e: any) => {
    this.props.history.goBack();
  }

  handleSearch = (e: any) => {
    this.fetch();
  }

  handleClear = async (e: any) => {
    await this.setState({
      nameSearch: undefined,
      dataTable: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
    });
    this.fetch();
  }

  handleInputSearch = (e: any) => {
    const search = e.target.value;
    this.setState({ nameSearch: search });
  }

  handleTableSourceChange = (pagination: any, filters: any, sorter: any) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({ pagination: pager });
    if (sorter.field) {
      this.fetch({ page: pager.current, limit: pager.pageSize, sorting: sorter.field, order: sorter.order });

    } else {
      this.fetch({ page: pager.current, limit: pager.pageSize });
    }
  }

  fetch = async (params: any = {}) => {
    const dataCarousel = this.state.dataCarousel;
    if (dataCarousel.length) {
      params.excludeId = dataCarousel.map((product: any) => product.id).toString();
    }

    if (this.state.nameSearch) {
      params.name = this.state.nameSearch;
    }
    params.status = 'active';
    this.setState({ loading: true });
    let dataSource: any = this.state.dataSource;
    const pagination = { ...this.state.pagination };
    const result = await this.productService.retrieveProducts(params);
    if (result) {
      if (result[0].length > 0)
        dataSource = result[0];
      else dataSource = [];
      pagination.total = result[1];
      this.setState({
        dataSource,
        pagination,
      });
    }
    this.setState({ loading: false });
  }

  fetchCarousel = async (id: any) => {
    let dataCarousel = this.state.dataCarousel;
    this.setState({ carouselLoading: true });
    const result = await this.productCategoryService.retrieveCarousel(id);
    if (result.length) {
      dataCarousel = result;
    } else dataCarousel = [];
    this.setState({ dataCarousel, carouselLoading: false });
  }

  render() {
    return (
      <div className="carousel">
        <Spin spinning={this.state.updateLoading} delay={200}>
          <div className="card-table">
            <h2 style={{ paddingLeft: '8px' }} data-at={'label_header_name'}>{this.state.categoryName}</h2>
            <DndProvider backend={HTML5Backend}>
              <Table columns={this.columnsCarousel}
                dataSource={this.state.dataCarousel}
                components={this.components}
                onRow={(record, index) => ({
                  index,
                  moveRow: this.moveRow,
                })}
                rowKey={(record: any) => record.id}
                pagination={false}
                loading={this.state.carouselLoading} />
            </DndProvider>
            <div className="save">
              <Button type="primary" icon="save" onClick={this.handleSave} data-at={'btn_save'}>Save</Button>
              <Button type="default" icon="left" onClick={this.handleCancel} data-at={'btn_back'}>Back</Button>
            </div>
          </div>

          <div className="card-table">
            <div className="search">
              <Input placeholder="Product name"
                value={this.state.nameSearch}
                onChange={this.handleInputSearch}
                data-at={'field_search'}
              />
              <Button type="primary" icon="search" onClick={this.handleSearch} data-at={'btn_search'}>Search</Button>
              <Button type="default" icon="sync" onClick={this.handleClear} data-at={'btn_clear'}>Clear</Button>
            </div>
            <Table columns={this.columnsSource}
              rowKey={(record: any) => record.id}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableSourceChange}
              dataSource={this.state.dataSource} />
          </div>
        </Spin>
      </div>
    )
  }
}

export default CarouselManagement;