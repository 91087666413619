import React, { Component } from 'react';
import {
  Form,
  Switch,
  Button,
  Upload,
  Icon,
  Input,
  message,
  Checkbox,
  Modal,
  Spin
} from 'antd';
import './PrivilegeForm.css';
import PrivilegeService from '../../../services/Privilege';

const { confirm } = Modal;

class PrivilegeForm extends Component<any, any> {
  privilegeService = new PrivilegeService();
  state = {
    mode: 'create',
    uploadDisabled: false,
    allowFile: false,
    privilege: {
      name: undefined,
      linkUrl: 'https://',
      image: undefined,
      status: 'inactive'
    },
    newImage: false,
    currentImage: undefined,
    updateLoading: false,
  };

  async componentWillMount() {
    console.log(this.props.match.params.id);
    if (this.props.match.params.id) {
      if (this.props.match.params.id === 'create') {
        document.title = 'LINE x MTL - CMS Privilege [Create]';
        this.setState({ mode: 'create' });
      } else {
        try {
          this.setState({ updateLoading: true });
          document.title = 'LINE x MTL - CMS Privilege [Edit]';
          const id = this.props.match.params.id;
          const privilege = await this.privilegeService.retrievePrivilege(id);
          if (privilege) {
            this.setState({ privilege, mode: 'update', currentImage: privilege.image });
          } else {
            this.props.history.replace('/privilege');
          }
          this.setState({ updateLoading: false });
        } catch (err) {
          this.props.history.replace('/privilege');
        }
      }
    } else {
      this.props.history.replace('/privilege');
    }
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const mode = this.state.mode;
        const privilege = this.state.privilege;
        privilege.name = values.name;
        privilege.linkUrl = values.linkUrl;
        privilege.status = (values.status) ? 'active' : 'inactive';
        privilege.image = values.upload;
        if (mode === 'create') {
          this.setState({ updateLoading: true });
          const result = await this.privilegeService.createPrivilege(privilege);
          if (result && result.id) {
            message.success('create successful');
            setTimeout(() => {
              this.props.history.replace('/privilege');
            }, 800);
          } else {
            message.error('create failed');
          }
          this.setState({ updateLoading: false });
        }

        if (mode === 'update') {
          const id = this.props.match.params.id;
          this.setState({ updateLoading: true });
          const activeExist = await this.privilegeService.checkPrivileges(id);
          this.setState({ updateLoading: false });
          const vm = this;
          if (activeExist && this.state.privilege.status === 'active') {
            confirm({
              title: 'Change to active this privilege',
              content: `Other privilege is active`,
              async onOk() {
                vm.setState({ updateLoading: true });
                const result = await vm.privilegeService.update(vm.state.privilege);
                if (result && result.id) {
                  message.success('update successful');
                  setTimeout(() => {
                    vm.props.history.replace('/privilege');
                  }, 800);
                } else {
                  message.error('update failed');
                }
                vm.setState({ updateLoading: false });
              },
            });
          } else {
            this.setState({ updateLoading: true });
            const result = await this.privilegeService.update(this.state.privilege);
            if (result && result.id) {
              message.success('update successful');
              setTimeout(() => {
                this.props.history.replace('/privilege');
              }, 800);
            } else {
              message.error('update failed');
            }
            this.setState({ updateLoading: false });
          }
        }
      }
    });
  };

  handleChangeFile = (info: any) => {
    setTimeout(() => {
      if (info.fileList.length) {
        if (this.state.allowFile) {
          this.setState({ uploadDisabled: true });
          const imgElement = document.getElementById('imagePreview') as HTMLImageElement;
          imgElement.src = window.URL.createObjectURL(info.file);
        } else {
          const filename = document.getElementsByClassName('anticon-close')[0] as HTMLElement;
          if (filename) {
            filename.click();
          }
        }

      } else {
        this.setState({ uploadDisabled: false });
      }
    }, 700);

  }

  beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    this.setState({ allowFile: isJpgOrPng });
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return false;
  }

  newImageHandle = (e: any) => {
    const checked = e.target.checked;
    if (!checked) {
      this.setState({ uploadDisabled: false });
    }
    this.setState({ newImage: checked });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };


    const uploadImage = (this.state.mode === 'create' || (this.state.mode === 'update' && this.state.newImage)) ?
      <Form.Item label="Image">
        <div className="image-preview">
          <img data-at="img_preview" alt="privilege"
            id="imagePreview"
            style={(this.state.uploadDisabled) ? { display: 'block' } : { display: 'none' }}
          />
          <Icon type="file-image" style={(this.state.uploadDisabled) ? { display: 'none' } : { display: 'block' }} />
        </div>
        {getFieldDecorator('upload', {
          rules: [{ required: true }],
          values: this.state.privilege.image
        })(
          <Upload
            accept="image/x-png,image/jpeg"
            onChange={this.handleChangeFile}
            beforeUpload={this.beforeUpload}
          >
            <Button data-at="btn_upload" disabled={this.state.uploadDisabled}>
              <Icon type="upload" /> Select File
        </Button>
          </Upload>
        )}
      </Form.Item> : null;

    const currentImage = (this.state.mode === 'update') ?
      <React.Fragment>
        <Form.Item label="Current Image">
          <div className="image-preview">
            <Icon type="file-image"
              style={(this.state.currentImage) ? { display: 'none' } : { display: 'block' }} />
            <img data-at="img_currentImage" alt="privilege"
              id="imagePreviewUpdate"
              src={this.state.currentImage}
              style={(this.state.currentImage) ? { display: 'block' } : { display: 'none' }}
            />
          </div>
        </Form.Item>
        <Form.Item label="New Image">
          <Checkbox data-at="btn_newImage" onChange={this.newImageHandle}></Checkbox>
        </Form.Item>
      </React.Fragment> : null;

    return (
      <div className="privilege-form">
        <Spin spinning={this.state.updateLoading} delay={200}>
          <Form {...formItemLayout} onSubmit={this.handleSubmit} className="form">
            <Form.Item label="Name">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please enter your privilege name' }],
                initialValue: this.state.privilege.name
              })(
                <Input data-at="field_name" placeholder="Privilege name" />
              )}
            </Form.Item>

            <Form.Item label="URL">
              {getFieldDecorator('linkUrl', {
                rules: [{
                  required: true, message: 'Please enter your privilege url',
                  pattern: /^(http|https):\/\/[^ "]+$/
                }],
                initialValue: this.state.privilege.linkUrl
              })(
                <Input data-at="field_url" placeholder="Example https://www.example.com" />
              )}
            </Form.Item>

            {currentImage}

            {uploadImage}

            <Form.Item label="Status">
              {getFieldDecorator('status', { valuePropName: 'checked', initialValue: this.state.privilege.status === 'active' })(
                <Switch data-at="btn_status" checkedChildren="Active" unCheckedChildren="Inactive" disabled={this.state.mode === 'create'} />
              )}
            </Form.Item>

            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
              <Button data-at="btn_submit" icon="save" type="primary" htmlType="submit">
                Submit
            </Button>
              <Button
                type="default"
                htmlType="button"
                style={{ marginLeft: '8px' }}
                onClick={() => this.props.history.goBack()}
                data-at="btn_back"
              >
                Back
            </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }

}

export default Form.create({ name: 'Privilege_Form' })(PrivilegeForm);