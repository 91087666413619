import React, { Component } from "react";
import { Table, Switch, message, Modal, Form } from "antd";
import moment from "moment";
import LineCCService from "../../services/LineCC";
import "./LineCC.css";
import UsersService from '../../services/Users'

const { confirm } = Modal;

class LineCC extends Component<any, any> {
  lineCCService = new LineCCService();
  userService = new UsersService()

  columns = [
    {
      title: "Date",
      dataIndex: "updatedDate",
      key: "updatedDate",
      render: (text: any, record: any) => {
        const date = moment(record.updatedDate).format("DD-MM-YYYY HH:mm:ss");
        return <span data-at={"label_date-" + record.id}>{date}</span>;
      }
    },
    {
      title: "Site",
      key: "site",
      dataIndex: "site"
    },
    {
      title: "Detail",
      key: "detail",
      dataIndex: "detail"
    },
    {
      title: "Link URL",
      key: "url",
      dataIndex: "url"
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text: any, record: any) => {
        const { getFieldDecorator } = this.props.form;
        return (
          <React.Fragment>
            {getFieldDecorator(`status_${record.id}`, {
              valuePropName: "checked",
              initialValue: record.status === "active"
            })(
              <Switch
                data-at="btn_status"
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={change => this.onChangeStatus(change, record)}
              />
            )}
          </React.Fragment>
        );
      }
    }
  ];

  state = {
    loading: false,
    defaultCheckId: 1,
    dataTable: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    }
  };

  async componentWillMount() {
    document.title = "LINE x MTL - CMS LINE CC Channel";
  }

  async componentDidMount() {
    this.fetch();
    this.userService.saveActivity({
      moduleName:'LINE CC',
      actionName: 'view'
    })
  }

  async componentWillUnmount() {}

  onChangeStatus = async (change: boolean, record: any) => {
    const vm = this;
    if (change && record.id !== this.state.defaultCheckId) {
      confirm({
        title: "Change channel",
        content: "Do you want to change active channel?",
        async onOk() {
          await vm.updateStatus(record.id);
          await vm.fetch();
          vm.userService.saveActivity({
            moduleName:'LINE CC',
            actionName: 'status',
            contentId: record.id
          })
        },
        async onCancel() {
          await vm.fetch();
        }
      });
    } else {
      if (record.id === this.state.defaultCheckId) {
        message.warning("Can not change status to inactive!");
        setTimeout(async () => {
          await vm.updateSwitch();
        }, 500);
      }
    }
  };

  updateStatus = async (newId: number) => {
    const dataTable = this.state.dataTable;

    const currentChannel = dataTable
      .filter((row: any) => row.id === this.state.defaultCheckId)
      .map((row: any) => {
        row.status = "inactive";
        return row;
      })[0];

    const newActiveChannel = dataTable
      .filter((row: any) => row.id === newId)
      .map((row: any) => {
        row.status = "active";
        return row;
      })[0];
    try {
      await this.lineCCService.update(currentChannel);
      await this.lineCCService.update(newActiveChannel);
      message.success("update success!");
    } catch (err) {
      message.error("update fail!");
    }
  };

  fetch = async () => {
    this.setState({ loading: true });
    const pagination = { ...this.state.pagination };
    const result = await this.lineCCService.retrieveLineCC();
    const dataTable = result[0];
    pagination.total = result[1];
    dataTable.forEach((row: any) => {
      if (row.status === "active") this.setState({ defaultCheckId: row.id });
    });
    await this.setState({ dataTable, pagination, loading: false });
    await this.updateSwitch();
  };

  updateSwitch = async () => {
    const vm = this;
    const dataTable = this.state.dataTable;
    dataTable.forEach((row: any) => {
      const value: any = {};
      value[`status_${row.id}`] = row.status === "active";
      vm.props.form.setFieldsValue(value);
    });
  };

  render() {
    return (
      <div className="linecc-list">
        <div className="card-table">
          <Table
            rowKey={(record: any) => record.id}
            columns={this.columns}
            loading={this.state.loading}
            dataSource={this.state.dataTable}
            pagination={false}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: "LineCC" })(LineCC);
