import React, { Component } from "react";
import { Form, Switch, Button, Input, message, Spin } from "antd";
import TrackingUrlService from "../../../services/TrackingUrl";
import "./TrackingUrlForm.css";
import UsersService from "../../../services/Users";

class TrackingUrlForm extends Component<any, any> {
  trackingUrlService = new TrackingUrlService();
  userService = new UsersService();
  state = {
    mode: "create",
    trackingUrl: {
      name: undefined,
      trackingKey: undefined,
      trackingUrl: undefined,
      status: "active",
    },
    updateLoading: false,
  };

  async componentWillMount() {
    if (this.props.match.url === "/tracking-url/create") {
      document.title = "LINE x MTL - CMS Tracking Url [Create]";
      this.setState({ mode: "create" });
    } else {
      this.setState({ updateLoading: true });
      document.title = "LINE x MTL - CMS Tracking Url [Edit]";
      const id = this.props.match.params.id;
      const trackingUrl = await this.trackingUrlService.retrieveTrackingUrl(id);
      if (trackingUrl) {
        trackingUrl.status = trackingUrl.status === "A" ? "active" : "inactive";
        this.setState({ trackingUrl, mode: "update" });
      } else {
        this.setState({ updateLoading: false });
        this.props.history.replace("/tracking-url");
      }
      this.setState({ updateLoading: false });
      this.userService.saveActivity({
        moduleName: "tracking url",
        actionName: "detail",
        contentId: id,
      });
    }
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const trackingUrl: any = this.state.trackingUrl;
        const mode = this.state.mode;
        trackingUrl.name = values.name.trim();
        trackingUrl.trackingKey = values.tracking_key.trim();
        trackingUrl.trackingUrl = values.tracking_url;
        trackingUrl.status = values.status ? "A" : "D";

        if (mode === "update") {
          this.setState({ updateLoading: true });
          const result = await this.trackingUrlService.update(
            this.state.trackingUrl
          );
          this.setState({ updateLoading: false });
          if (result && result.update) {
            message.success("update successful");
            this.userService.saveActivity({
              moduleName: "Tracking Url",
              actionName: "update",
              contentId: trackingUrl.id,
            });
            setTimeout(() => {
              this.props.history.replace("/tracking-url");
            }, 800);
          } else if (!!result.code && result.code === "ER_DUP_ENTRY") {
            message.error("Tracking key Duplicate");
          } else {
            message.error("update failed");
            setTimeout(() => {
              this.props.history.replace("/tracking-url");
            }, 800);
          }
        }

        if (mode === "create") {
          this.setState({ updateLoading: true });
          const result = await this.trackingUrlService.create(
            this.state.trackingUrl
          );
          if (result && result.id > -1) {
            this.userService.saveActivity({
              moduleName: "tracking url",
              actionName: "create",
              contentId: result.id,
            });
            message.success("create successful");
            setTimeout(() => {
              this.props.history.replace("/tracking-url");
            }, 1000);
          } else if (!!result.code && result.code === "ER_DUP_ENTRY") {
            message.error("Tracking key Duplicate");
          }
          this.setState({ updateLoading: false });
        }
      }
    });
  };

  checkBlank = (rule: any, value: any, callback: any) => {
    if (value.trim() === "" || value.trim().length === 0) {
      rule.message = "Do not use white space.";
      callback(false);
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return (
      <div className="tracking-url-form">
        <Spin spinning={this.state.updateLoading} delay={200}>
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            className="form"
          >
            <Form.Item label="Name">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your tracking name",
                  },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.trackingUrl.name,
              })(
                <Input data-at="field_name" placeholder="Tracking url name" />
              )}
            </Form.Item>

            <Form.Item label="Tracking Key">
              {getFieldDecorator("tracking_key", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your tracking key",
                  },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.trackingUrl.trackingKey,
              })(
                <Input
                  data-at="field_tracking_key"
                  disabled={this.state.mode === "update"}
                  placeholder="Please enter key for tracking"
                />
              )}
            </Form.Item>
            <Form.Item label="Tracking Url">
              {getFieldDecorator("tracking_url", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your tracking url",
                    pattern: /^(http|https):\/\/[^ "]+$/,
                  },
                ],
                initialValue: this.state.trackingUrl.trackingUrl,
              })(
                <Input
                  data-at="field_url"
                  placeholder="Example https://www.example.com"
                />
              )}
            </Form.Item>

            <Form.Item label="Status">
              {getFieldDecorator("status", {
                valuePropName: "checked",
                initialValue: this.state.trackingUrl.status === "active",
              })(
                <Switch
                  data-at="btn_status"
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              )}
            </Form.Item>

            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
              <Button
                data-at="btn_submit"
                icon="save"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
              <Button
                type="default"
                htmlType="button"
                style={{ marginLeft: "8px" }}
                onClick={() => this.props.history.goBack()}
                data-at="btn_back"
              >
                Back
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create({ name: "tracking_url" })(TrackingUrlForm);
