import RequestService from './HttpRequestService';

class MaintenanceService {
  requestService = new RequestService();
  async getMaintenance() {
    try {
        const response = await this.requestService.get('/maintenance')
        return response
    } catch (error) {
        throw error.message;
    }
  }

  async saveMaintenance(data: any) {
    try {
        const response = await this.requestService.post('/maintenance', data)
        return response
    } catch (error) {
        throw error.message;
    }
  }
}

export default MaintenanceService;