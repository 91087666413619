import fileDownload from 'js-file-download';
import RequestService from './HttpRequestService';

class CustomerService {
  requestService = new RequestService();
  async unlinkCustomer(params: any = {}) {
    return this.requestService.post('/customers/unlink', params);
  }

  async linkCustomer(params: any = {}) {
    return this.requestService.post('/customers/link', params);
  }

  async retrieveCustomer(params: any = {}) {
    return this.requestService.get('/customers', params);
  }

  async retrieveCustomerCSV(params: any = {}) {
    const result = await this.requestService.get('/customers/csv', params);
    const blob = new Blob(["\ufeff", result]);
    fileDownload(blob, 'customer.csv');
  }
}

export default CustomerService;