import fileDownload from 'js-file-download';
import RequestService from './HttpRequestService';

class MessageService {
  requestService = new RequestService();
  async retrieveMessage(params: any = {}) {
    return this.requestService.get('/message', params);
  }

  async retrieveMessageStatus() {
    return this.requestService.get('/message/status');
  }

  async retrieveMessageCSV(params: any = {}) {
    const result = await this.requestService.get('/message/csv', params);
    if(result) {
      const blob = new Blob(["\ufeff", result]);
      fileDownload(blob, 'message.csv');
    }
    
  }

}

export default MessageService;