import React, { Component } from 'react';
import { DatePicker, Input, Select, Button, Table } from 'antd';
import moment from 'moment';
import './Message.css';
import MessageService from '../../services/Message';
import UsersService from '../../services/Users'

const { Option } = Select;
const { RangePicker } = DatePicker;

class Message extends Component<any, any> {
  messageService = new MessageService();
  userService = new UsersService()
  columns = [
    {
      title: 'Datetime',
      dataIndex: 'created_date',
      key: 'created_date',
      render: (text: any, record: any) => {
        const date = moment(record.created_date).format('YYYY-MM-DD HH:mm:ss');
        return <span data-at={'label_date-' + record.uid}>{date}</span>
      },
      sorter: true
    },
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
      sorter: true,
      render: (text: any, record: any) => {
        if (text) {
          return <span data-at={'label_uuid-' + record.uuid}>{text}</span>
        } else {
          return null;
        }
      }
    },
    {
      title: 'Phone',
      dataIndex: 'tel',
      key: 'tel',
      sorter: true,
      render: (text: any, record: any) => {
        if (text) {
          return <span>{text}</span>
        } else {
          return null;
        }
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text: any, record: any) => {
        text = text.charAt(0).toUpperCase() + text.slice(1);
        return <span data-at={'label_type-' + record.uid}>{text}</span>;
      },
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: any, record: any) => {
        text = text.charAt(0).toUpperCase() + text.slice(1);
        return <span data-at={'label_status-' + record.uid}>{text}</span>;
      },
      sorter: true,
    },
  ];

  state = {
    dateSearch: [],
    phoneSearch: undefined,
    typeSearch: undefined,
    statusSearch: undefined,
    dataTable: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    },
    loading: false,
    exporting: false,
    status: ['all'],
    role: undefined,
  }

  componentWillMount() {
    document.title = 'LINE x MTL - CMS Message';
    const userRole = window.localStorage.getItem("role");
    this.setState({ role: userRole });
  }

  componentDidMount() {
    this.fetch();
    this.fetchStatus();
    this.userService.saveActivity({
      moduleName:'message',
      actionName: 'view'
    })
  }

  handleDatepicker = (dateMoment: any) => {
    this.setState({ dateSearch: dateMoment });
  }

  handlePhone = (e: any) => {
    const phone = e.target.value;
    this.setState({ phoneSearch: phone });
  }

  handleType = (type: any) => {
    this.setState({ typeSearch: type });
  }

  handleStatus = (status: any) => {
    this.setState({ statusSearch: status });
  }

  handleSearch = (e: any) => {
    this.fetch();
    this.fetchStatus();
  }

  handleClear = async (e: any) => {
    await this.setState({
      dateSearch: [],
      phoneSearch: undefined,
      typeSearch: undefined,
      statusSearch: undefined,
    });
    this.fetch();
    this.fetchStatus();
  }

  handleExport = async (e: any) => {
    this.setState({ exporting: true });
    let params: any = {};
    if (this.state.dateSearch.length) {
      let d1 = moment(this.state.dateSearch[0]).format('YYYY-MM-DD');
      let d2 = moment(this.state.dateSearch[1]).format('YYYY-MM-DD');
      const created_date = `${d1},${d2}`;
      params.created_date = created_date;
    }
    if (this.state.phoneSearch) {
      params.tel = this.state.phoneSearch;
    }
    if (this.state.typeSearch) {
      params.type = this.state.typeSearch;
    }
    if (this.state.statusSearch) {
      params.status = this.state.statusSearch;
    }
    await this.messageService.retrieveMessageCSV(params);
    this.setState({ exporting: false });
    this.userService.saveActivity({
      moduleName:'message',
      actionName: 'export'
    })
  }

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({ pagination: pager });
    if (sorter.field) {
      this.fetch({ page: pager.current, limit: pager.pageSize, sorting: sorter.field, order: sorter.order });

    } else {
      this.fetch({ page: pager.current, limit: pager.pageSize });
    }
  }

  fetchStatus = async () => {
    const status = await this.messageService.retrieveMessageStatus();
    const allStatus = ['all', ...status];
    this.setState({ status: allStatus });
  }

  fetch = async (params: any = {}) => {
    if (this.state.dateSearch.length) {
      let d1 = moment(this.state.dateSearch[0]).format('YYYY-MM-DD');
      let d2 = moment(this.state.dateSearch[1]).format('YYYY-MM-DD');
      const created_date = `${d1},${d2}`;
      params.created_date = created_date;
    }
    if (this.state.phoneSearch) {
      params.tel = this.state.phoneSearch;
    }
    if (this.state.typeSearch) {
      params.type = this.state.typeSearch;
    }
    if (this.state.statusSearch) {
      params.status = this.state.statusSearch;
    }
    this.setState({ loading: true });
    let dataTable: any = this.state.dataTable;
    const pagination = { ...this.state.pagination };
    const result = await this.messageService.retrieveMessage(params);
    if (result) {
      if (result[0].length > 0)
        dataTable = result[0];
      else dataTable = [];
      pagination.total = result[1];
      this.setState({
        dataTable,
        pagination,
      });
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <div className="message">
        <div className="input-filter">
          <div className="input-item">
            <span className="text">Date</span>
            <RangePicker className="input"
              onChange={this.handleDatepicker}
              value={this.state.dateSearch}
              data-at="field-date"
            />
          </div>
          <div className="input-item">
            <span className="text">Phone</span>
            <Input placeholder="Phone"
              className="input"
              onChange={this.handlePhone}
              onKeyUp={(e)=> e.keyCode === 13 ? this.handleSearch(e): ''}
              value={this.state.phoneSearch}
              data-at="field-phone"
            />
          </div>
          <div className="input-item short">
            <span className="text">Type</span>
            <Select
              className="input"
              placeholder="All"
              onChange={this.handleType}
              value={this.state.typeSearch}
              data-at="field-type"
            >
              <Option data-at="type_option-all" value="all">All</Option>
              <Option data-at="type_option-text" value="text">Text</Option>
              <Option data-at="type_option-sticker" value="sticker">Sticker</Option>
              <Option data-at="type_option-image" value="image">Image</Option>
              <Option data-at="type_option-video" value="video">Video</Option>
              <Option data-at="type_option-audio" value="audio">Audio</Option>
              <Option data-at="type_option-location" value="location">Location</Option>
              <Option data-at="type_option-template" value="template">Template</Option>
              <Option data-at="type_option-flex" value="flex">Flex</Option>
            </Select>
          </div>
          <div className="input-item short">
            <span className="text">Status</span>
            <Select
              className="input"
              placeholder="All"
              onChange={this.handleStatus}
              value={this.state.statusSearch}
              data-at="field-status"
            >
              {
                this.state.status.map(text => 
                  <Option data-at="status_option-fail" key={text} value={text}>{text.charAt(0).toUpperCase() + text.slice(1)}</Option>
                )
              }
            </Select>
          </div>
          <div className="input-item button">
            <span className="text"></span>
            <div className="btn">
              <Button type="primary"
                icon="search"
                onClick={this.handleSearch}
                data-at="btn-search"
              >
                Search
              </Button>
              <Button type="default"
                onClick={this.handleClear}
                icon="sync"
                data-at="btn-clear"
                className="btn-custom-export"
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
        <div className="export">
          <Button 
            type="default" 
            icon="download" 
            onClick={this.handleExport}
            loading={this.state.exporting}
            disabled={this.state.exporting}  
            data-at="btn-export"
            className="btn-custom-export"
            style={{display: this.state.role !== 'admin' ? 'none': ''}}
          >
            Export
          </Button>
        </div>
        <div className="card-table">
          <Table columns={this.columns}
            rowKey={(record: any) => record.id}
            loading={this.state.loading}
            pagination={this.state.pagination}
            dataSource={this.state.dataTable}
            onChange={this.handleTableChange} />
        </div>
      </div>
    )
  }
}

export default Message;