import React, { Component } from 'react';
import { Spin } from 'antd';
import './PageNotFound.css';
import { NavLink } from 'react-router-dom';

class PageNotFound extends Component<any, any> {
  state = { loading: true };

  componentWillMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  render() {
    return (
      <div className="pagenotfound">
        <Spin spinning={this.state.loading} delay={0}>
          <h1 style={{display: this.state.loading ? 'none' : 'block'}}>404 Page Not Found</h1>
          <NavLink style={{display: this.state.loading ? 'none' : 'block'}} to='/'>Click to Homepage</NavLink>
        </Spin>
      </div>
    )
  }
}

export default PageNotFound;