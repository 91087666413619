import React, { Component } from "react";
import "./AgentBacklist.css";
import { DatePicker, Form, Button, Table, Row, Col, message } from "antd";
import moment from "moment";
import AgentBacklistService from "../../services/AgentBacklist";
import { NavLink } from "react-router-dom";
import UsersService from "../../services/Users";

class AgentBacklist extends Component<any, any> {
  agentBacklistService = new AgentBacklistService();
  userService = new UsersService();
  state = {
    loading: true,
    dataTable: [],
    pagination: {
      defaultPageSize: 10,
      total: 0,
      current: 1,
    },
  };
  async componentDidMount() {
    const response = await this.agentBacklistService.getAgentBacklist(
      this.state.pagination.current
    );
    this.setState({
      loading: false,
      dataTable: response.items,
      pagination: {
        ...this.state.pagination,
        total: response.count,
      },
    });
    this.userService.saveActivity({
      moduleName: "agent backlist",
      actionName: "view",
    });
  }
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await this.agentBacklistService.getCSVAgentBacklist(
            values.updated_date.format("YYYY-MM-DD")
          );
          this.userService.saveActivity({
            moduleName: "agent backlist",
            actionName: "export",
          });
        } catch (error) {
          message.error(error);
        }
      }
    });
  };
  handleTableChange = async (pagination: any) => {
    this.setState({ loading: true });
    const response = await this.agentBacklistService.getAgentBacklist(
      pagination.current
    );
    this.setState({
      loading: false,
      dataTable: response.items,
      pagination: {
        ...this.state.pagination,
        total: response.count,
        current: pagination.current,
      },
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Telephone",
        dataIndex: "telephone",
        key: "telephone",
      },
      {
        title: "Agent ID Card",
        dataIndex: "agentIdcard",
        key: "agentIdcard",
      },
      {
        title: "Receive ID Card",
        dataIndex: "recieveIdcard",
        key: "recieveIdcard",
      },
      {
        title: "Date",
        dataIndex: "updatedDate",
        key: "updatedDate",
        render: (text: any, record: any) => {
          console.log(text);
          const date = moment(record.updatedDate).utc().format("YYYY-MM-DD HH:mm:ss");
          return record.updatedDate ? (
            <span data-at={"label_date-" + record.uid}>{date}</span>
          ) : (
            "-"
          );
        },
      },
    ];
    return (
      <div className="agent-backlist">
        <Form onSubmit={this.handleSubmit} layout="inline">
          <Row>
            <Col md={6} xl={4}>
              <Form.Item
                label="Date"
                {...{
                  labelCol: {
                    md: {
                      span: 8,
                    },
                  },
                  wrapperCol: {
                    md: {
                      span: 16,
                    },
                  },
                }}
              >
                {getFieldDecorator("updated_date", {
                  rules: [{ required: true, message: "Please select date!" }],
                })(<DatePicker />)}
              </Form.Item>
            </Col>
            <Col xl={8} md={10}>
              <Button
                type="primary"
                icon="download"
                htmlType="submit"
                data-at="btn-search"
              >
                Export
              </Button>
              <NavLink to="/agent-backlist/monthly">
                <Button
                  type="default"
                  icon="container"
                  data-at="btn-search"
                  style={{ marginLeft: "10px" }}
                >
                  Monthly Report
                </Button>
              </NavLink>
            </Col>
          </Row>
        </Form>
        <div className="card-table">
          <Table
            columns={columns}
            rowKey={(record: any) => record.id}
            loading={this.state.loading}
            dataSource={this.state.dataTable}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default Form.create({ name: "agent_backlist" })(AgentBacklist);
