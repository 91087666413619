import React, { Component } from "react";
import { Table, DatePicker, Input, Button, Modal, message } from "antd";
import moment from "moment";
import LocationService from "../../services/Location";
import "./Location.css";
import UsersService from "../../services/Users";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

class Hospital extends Component<any, any> {
  locationService = new LocationService();
  userService = new UsersService();
  state = {
    mode: "hospital",
    dateSearch: [],
    nameSearch: undefined,
    statusSearch: undefined,
    dataTable: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    loading: false,
    exporting: false,
    role: undefined,
  };

  columns = [
    {
      title: "Date",
      dataIndex: "id",
      key: "id",
      sorter: true,
      width: "15%",
      render: (text: any, record: any) => {
        const date = moment(record.created_date).format("YYYY-MM-DD");
        return <span data-at={"label_date-" + record.id}>{date}</span>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text: any, record: any) => {
        return (
          <span
            data-at={"label_name-" + record.id}
            style={{ fontSize: "16px", fontFamily: "Kalatexa" }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Tracking label",
      dataIndex: "trackingLabel",
      key: "trackingLabel",
      sorter: true,
      render: (text: any, record: any) => {
        return (
          <span data-at={"tracking_label-" + record.id}>
            {`hospital-map-${record.id},`}
            <br />
            {` hospital-tel-${record.id}`}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => {
        const status = text.charAt(0).toUpperCase() + text.slice(1);
        return <span data-at={"label_status-" + record.id}>{status}</span>;
      },
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <div className="btn-row">
          <Button
            ghost
            type="primary"
            icon="edit"
            size="small"
            onClick={() => this.handleEditLocation(record)}
            data-at={"btn_edit-" + record.id}
            className="btn-custom-blue"
          >
            Edit
          </Button>
          <Button
            ghost
            type="danger"
            icon="delete"
            size="small"
            className="btn-custom-red"
            onClick={() => this.handleDeleteLocation(record)}
            data-at={"btn_delete-" + record.id}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  async componentWillMount() {
    await this.setupPage();
  }

  async setupPage() {
    document.title = "LINE x MTL - CMS Location Hospital";
    this.setState({ mode: "hospital" });
  }

  async componentDidMount() {
    const userRole = window.localStorage.getItem("role");
    this.setState({ role: userRole });
    this.fetch();
    this.userService.saveActivity({
      moduleName: `location hospital`,
      actionName: "view",
    });
  }

  async componentWillUnmount() {
    delete this.locationService;
  }

  handleCreate = (e: any) => {
    const mode = this.state.mode;
    this.props.history.push(`/location/${mode}/create`);
  };

  handleDatepicker = (dateMoment: any) => {
    this.setState({ dateSearch: dateMoment });
  };

  handleName = (e: any) => {
    const value = e.target.value;
    this.setState({ nameSearch: value });
  };

  handleSearch = (e: any) => {
    this.fetch();
  };

  handleClear = async (e: any) => {
    await this.setState({
      dateSearch: [],
      nameSearch: undefined,
      statusSearch: undefined,
      dataTable: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      exporting: false,
    });
    this.fetch();
  };

  handleExport = async (e: any) => {
    this.setState({ exporting: true });
    let params: any = {};
    if (this.state.dateSearch.length) {
      let d1 = moment(this.state.dateSearch[0]).format("YYYY-MM-DD");
      let d2 = moment(this.state.dateSearch[1]).format("YYYY-MM-DD");
      params.updated_date = `${d1},${d2}`;
    }
    if (this.state.nameSearch) {
      params.name = this.state.nameSearch;
    }
    params.locationType = this.state.mode;
    await this.locationService.retrieveLocationCSV(params);
    this.setState({ exporting: false });
    this.userService.saveActivity({
      moduleName: `location ${this.state.mode}`,
      actionName: "export",
    });
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({ pagination: pager });
    if (sorter.field) {
      this.fetch({
        page: pager.current,
        limit: pager.pageSize,
        sorting: sorter.field,
        order: sorter.order,
      });
    } else {
      this.fetch({ page: pager.current, limit: pager.pageSize });
    }
  };

  handleEditLocation = (record: any) => {
    this.props.history.push(`/location/${this.state.mode}/${record.id}`);
  };

  handleDeleteLocation = async (record: any) => {
    const vm = this;
    const dataTable: any = this.state.dataTable;
    confirm({
      title: "Do you want to delete these active item?",
      content: `${record.name} will delete`,
      onOk: async () => {
        const result = await vm.locationService.delete(record.id);
        if (result && result.delete) {
          dataTable.splice(dataTable.indexOf(record), 1);
          await vm.setState({ dataTable });
          await vm.fetch({ locationType: this.state.mode });
          message.success("deleted!");
          vm.userService.saveActivity({
            moduleName: `location ${this.state.mode}`,
            actionName: "delete",
            contentId: record.id,
          });
          return result.delete;
        }
      },
    });
  };

  fetch = async (params: any = {}) => {
    params.locationType = this.state.mode;
    if (this.state.dateSearch.length) {
      let d1 = moment(this.state.dateSearch[0]).format("YYYY-MM-DD");
      let d2 = moment(this.state.dateSearch[1]).format("YYYY-MM-DD");
      params.updated_date = `${d1},${d2}`;
    }
    if (this.state.nameSearch) {
      params.name = this.state.nameSearch;
    }
    this.setState({ loading: true });
    let dataTable: any = this.state.dataTable;
    const pagination = { ...this.state.pagination };
    const result = await this.locationService.retrieveLocations(params);
    if (result) {
      if (result[0].length > 0) dataTable = result[0];
      else dataTable = [];
      pagination.total = result[1];
      this.setState({
        dataTable,
        pagination,
      });
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="location">
        <div className="input-filter">
          <div className="input-item">
            <span className="text">Date</span>
            <RangePicker
              onChange={this.handleDatepicker}
              value={this.state.dateSearch}
              className="input"
              format="YYYY-MM-DD"
              data-at="field_date"
            />
          </div>
          <div className="input-item">
            <span className="text">Name</span>
            <Input
              placeholder="Name"
              className="input"
              data-at="field_name"
              onChange={this.handleName}
              value={this.state.nameSearch}
              onKeyUp={(e) => (e.keyCode === 13 ? this.handleSearch(e) : "")}
            />
          </div>
          <div className="input-item button">
            <span className="text"></span>
            <div className="btn">
              <Button
                type="primary"
                icon="search"
                data-at="btn_search"
                onClick={this.handleSearch}
              >
                Search
              </Button>
              <Button
                type="default"
                icon="sync"
                data-at="btn-clear"
                className="btn-custom-export"
                onClick={this.handleClear}
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
        <div className="export-bar">
          <div className="create">
            <Button
              data-at="btn-create"
              type="default"
              icon="plus-circle"
              className="btn-custom-darkgreen"
              onClick={this.handleCreate}
            >
              Create
            </Button>
          </div>
          <Button
            type="default"
            icon="download"
            data-at="export"
            className="btn-custom-export"
            onClick={this.handleExport}
            loading={this.state.exporting}
            disabled={this.state.exporting}
            style={{ display: this.state.role !== "admin" ? "none" : "" }}
          >
            Export
          </Button>
        </div>
        <div className="card-table">
          <Table
            columns={this.columns}
            rowKey={(record: any) => record.id}
            pagination={this.state.pagination}
            loading={this.state.loading}
            dataSource={this.state.dataTable}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    );
  }
}

export default Hospital;
