import RequestService from "./HttpRequestService";

class LineCC {
  requestService = new RequestService();

  async retrieveLineCC() {
    return this.requestService.get("/linecc", {});
  }

  async update(linecc: any = {}) {
    const id = linecc.id;
    return this.requestService.put(`/linecc/${id}`, linecc);
  }
}

export default LineCC;
