import fileDownload from 'js-file-download';
import RequestService from './HttpRequestService';
import moment from 'moment'

class AgentBacklistService {
  requestService = new RequestService();
  async getCSVAgentBacklist(date: string) {
    try {
        const response = await this.requestService.get('/register/export/agent_backlist', {date})
        const blob = new Blob(["\ufeff", response]);
        fileDownload(blob, `agent-backlist-${moment().format('YYYYMMDDHHmmss')}.csv`);
    } catch (error) {
        throw error.message;
    }
  }

  async getAgentBacklist(page: number) {
    try {
        const response = await this.requestService.get('/register/agent_backlist',{page})
        return response
    } catch (error) {
        throw error.message;
    }
  }

  async getAgentBacklistMonthly(page: number) {
    try {
        const response = await this.requestService.get('/register/agent_backlist/monthly',{page})
        return response
    } catch (error) {
        throw error.message;
    }
  }

  async getAgentBacklistCsvFileMonthly(id: number) {
    try {
        const response = await this.requestService.get('/register/agent_backlist/monthly/file',{id})
        return response
    } catch (error) {
        throw error.message;
    }
  }
}

export default AgentBacklistService;