import RequestService from './HttpRequestService';
import fileDownload from 'js-file-download';

class ProductCategory {
  requestService = new RequestService();

  async retrieveProductCategories(params: any = {}) {
    return this.requestService.get('/product-category', params);
  }

  async retrieveProductCategoriesCSV(params: any = {}) {
    const result = await this.requestService.get('/product-category/csv', params);
    const blob = new Blob(["\ufeff", result]);
    fileDownload(blob, 'product-category.csv');
  }

  async retrieveProductCategory(id: any) {
    return this.requestService.get(`/product-category/${id}`);
  }

  async create(productCategory: any) {
    return this.requestService.post('/product-category', productCategory);
  }

  async update(productCategory: any) {
    const id = productCategory.id;
    return this.requestService.put(`/product-category/${id}`, productCategory);
  }

  async delete(id: any) {
    return this.requestService.delete('/product-category', id);
  }


  async saveCarousel(carousel: any, categoryId: any) {
    return this.requestService.post(`/product-category/carousel/${categoryId}`, {carousel});
  }
  
  async retrieveCarousel(id: any) {
    const result = await this.requestService.get(`/product-category/carousel/${id}`);
    if (result) {
      result.map((row: any) => {
        row.updated_date = row.updatedDate;
        row.updated_by = row.updatedBy;
        row.created_date = row.createdDate;
        row.created_by = row.createdBy;
        return row;
      })
      return result;
    }
  }
}

export default ProductCategory;