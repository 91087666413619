import React, { Component } from "react";
import {
  Form,
  Switch,
  Button,
  Upload,
  Icon,
  Input,
  message,
  Checkbox,
  Modal,
  Spin,
} from "antd";
import "./ProductForm.css";
import ProductService from "../../../services/Product";
import UsersService from "../../../services/Users";

class ProductForm extends Component<any, any> {
  productService = new ProductService();
  userService = new UsersService();
  state = {
    mode: "create",
    uploadDisabled: false,
    allowFile: false,
    product: {
      name: undefined,
      detail: undefined,
      linkUrl: "https://",
      image: undefined,
      status: "active",
    },
    newImage: false,
    currentImage: undefined,
    updateLoading: false,
  };

  async componentWillMount() {
    if (this.props.match.params.id) {
      if (this.props.match.params.id === "create") {
        document.title = "LINE x MTL - CMS Product [Create]";
        this.setState({ mode: "create" });
      } else {
        try {
          this.setState({ updateLoading: true });
          document.title = "LINE x MTL - CMS Product [Edit]";
          const id = this.props.match.params.id;
          const product = await this.productService.retrieveProduct(id);
          if (product) {
            this.userService.saveActivity({
              moduleName: "product",
              actionName: "detail",
              contentId: id,
            });
            this.setState({
              product,
              mode: "update",
              currentImage: product.image,
            });
          } else {
            this.props.history.replace("/product-category/product");
          }
          this.setState({ updateLoading: false });
        } catch (err) {
          this.props.history.replace("/product-category/product");
        }
      }
    } else {
      this.props.history.replace("/product-category/product");
    }
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const mode = this.state.mode;
        const product = this.state.product;
        product.name = values.name;
        product.linkUrl = values.linkUrl;
        product.status = values.status ? "active" : "inactive";
        product.image = values.upload;
        product.detail = values.detail;

        if (mode === "create") {
          this.setState({ updateLoading: true });
          const result = await this.productService.createProduct(product);
          if (result && result.id) {
            this.userService.saveActivity({
              moduleName: "product",
              actionName: "create",
              contentId: result.id,
            });
            message.success("create successful");
            setTimeout(() => {
              this.props.history.replace("/product-category/product");
            }, 800);
          } else {
            message.error("create failed");
          }
          this.setState({ updateLoading: false });
        }

        if (mode === "update") {
          this.setState({ updateLoading: true });
          const product = this.state.product;
          const id = this.props.match.params.id;
          if (this.state.product.status === "inactive") {
            const used = await this.productService.getUsedProduct(id);
            if (used.amount > 0) {
              Modal.error({
                title: `Can't save product status inactive`,
                content: "Product is used in carousel",
              });
              product.status = "active";
              this.setState({ product });
            } else {
              const result = await this.productService.update(product);
              if (result && result.update) {
                this.userService.saveActivity({
                  moduleName: "product",
                  actionName: "update",
                  contentId: id,
                });
                message.success("update successful");
              } else {
                message.error("update failed");
              }
              setTimeout(() => {
                this.props.history.replace("/product-category/product");
              }, 800);
            } // else amount
          } else {
            const result = await this.productService.update(product);
            if (result && result.update) {
              this.userService.saveActivity({
                moduleName: "product",
                actionName: "update",
                contentId: id,
              });
              message.success("update successful");
            } else {
              message.error("update failed");
            }
            setTimeout(() => {
              this.props.history.replace("/product-category/product");
            }, 800);
          } // else 'inactive'
          this.setState({ updateLoading: false });
        } // update
      }
    });
  };

  handleChangeFile = (info: any) => {
    setTimeout(() => {
      if (info.fileList.length) {
        if (this.state.allowFile) {
          this.setState({ uploadDisabled: true });
          const imgElement = document.getElementById(
            "imagePreview"
          ) as HTMLImageElement;
          imgElement.src = window.URL.createObjectURL(info.file);
        } else {
          const filename = document.getElementsByClassName(
            "anticon-close"
          )[0] as HTMLElement;
          if (filename) {
            filename.click();
          }
        }
      } else {
        this.setState({ uploadDisabled: false });
      }
    }, 500);
  };

  beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    this.setState({ allowFile: isJpgOrPng });
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false;
  };

  newImageHandle = async (e: any) => {
    const checked = e.target.checked;
    if (!checked) {
      this.setState({ uploadDisabled: false });
    }
    this.setState({ newImage: checked });
  };

  checkBlank = (rule: any, value: any, callback: any) => {
    if (value.trim() === "" || value.trim().length === 0) {
      rule.message = "Do not use white space.";
      callback(false);
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const uploadImage =
      this.state.mode === "create" ||
      (this.state.mode === "update" && this.state.newImage) ? (
        <Form.Item label="Image">
          <div className="image-preview">
            <img
              data-at="img_preview"
              alt="product"
              id="imagePreview"
              style={
                this.state.uploadDisabled
                  ? { display: "block" }
                  : { display: "none" }
              }
            />
            <Icon
              type="file-image"
              style={
                this.state.uploadDisabled
                  ? { display: "none" }
                  : { display: "block" }
              }
            />
          </div>
          {getFieldDecorator("upload", {
            rules: [{ required: true }],
            values: this.state.product.image,
          })(
            <Upload
              accept="image/x-png,image/jpeg"
              onChange={this.handleChangeFile}
              beforeUpload={this.beforeUpload}
            >
              <Button data-at="btn_upload" disabled={this.state.uploadDisabled}>
                <Icon type="upload" /> Select File
              </Button>
            </Upload>
          )}
          <span style={{ fontSize: "0.85em" }}>
            *Image ratio 21:13 (Example 620x384, 500x310 pixel or similar size)
          </span>
        </Form.Item>
      ) : null;

    const currentImage =
      this.state.mode === "update" ? (
        <React.Fragment>
          <Form.Item label="Current Image">
            <div className="image-preview">
              <Icon
                type="file-image"
                style={
                  this.state.currentImage
                    ? { display: "none" }
                    : { display: "block" }
                }
              />
              <img
                data-at="img_currentImage"
                alt="product"
                id="imagePreviewUpdate"
                src={this.state.currentImage}
                style={
                  this.state.currentImage
                    ? { display: "block" }
                    : { display: "none" }
                }
              />
            </div>
          </Form.Item>
          <Form.Item label="New Image">
            <Checkbox
              data-at="btn_newImage"
              onChange={this.newImageHandle}
            ></Checkbox>
          </Form.Item>
        </React.Fragment>
      ) : null;

    return (
      <div className="product-form">
        <Spin spinning={this.state.updateLoading} delay={200}>
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            className="form"
          >
            <Form.Item label="Name">
              {getFieldDecorator("name", {
                rules: [
                  { required: true, message: "Please enter your product name" },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.product.name,
              })(<Input data-at="field_name" placeholder="Product name" />)}
            </Form.Item>

            <Form.Item label="Detail">
              {getFieldDecorator("detail", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your product detail",
                  },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.product.detail,
              })(
                <Input.TextArea
                  data-at="field_detail"
                  maxLength={255}
                  placeholder="Product detail"
                />
              )}
            </Form.Item>

            <Form.Item label="URL">
              {getFieldDecorator("linkUrl", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your product url",
                    pattern: /^(http|https):\/\/[^ "]+$/,
                  },
                ],
                initialValue: this.state.product.linkUrl,
              })(
                <Input
                  data-at="field_url"
                  placeholder="Example https://www.example.com"
                />
              )}
            </Form.Item>

            {currentImage}

            {uploadImage}

            <Form.Item label="Status">
              {getFieldDecorator("status", {
                valuePropName: "checked",
                initialValue: this.state.product.status === "active",
              })(
                <Switch
                  data-at="btn_status"
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              )}
            </Form.Item>

            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
              <Button
                data-at="btn_submit"
                icon="save"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
              <Button
                type="default"
                htmlType="button"
                style={{ marginLeft: "8px" }}
                onClick={() => this.props.history.goBack()}
                data-at="btn_back"
              >
                Back
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create({ name: "Product_Form" })(ProductForm);
