import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PageLayout from './PageLayout';

const PrivateRoute = ({component, isAuthenticated, ...rest}: any) => {
  const routeComponent = (props: any) => (
    isAuthenticated
          ? <PageLayout {...rest} {...props}>{React.createElement(component, props)}</PageLayout>
          : <Redirect to={{pathname: '/login', state: { from: props.location }}}/>
  );
  return <Route {...rest} render={routeComponent}/>;
};

export default PrivateRoute;