import RequestService from "./HttpRequestService";
import fileDownload from "js-file-download";

class Location {
  requestService = new RequestService();

  async createLocation(params: any = {}) {
    const formData = new FormData();
    for (let key in params) {
      if (params[key]) {
        formData.append(key, params[key]);
      }
    }
    formData.append("file", params.image.file);
    return this.requestService.post("/location", formData, true);
  }

  async update(location: any = {}) {
    const id = location.id;
    const formData = new FormData();
    if (location.image) {
      formData.append("file", location.image.file);
    }
    delete location.id;
    delete location.image;
    for (let key in location) {
      if (location[key]) {
        formData.append(key, location[key]);
      }
    }
    return this.requestService.post(`/location/${id}`, formData, true);
  }

  async retrieveLocations(params: any = {}) {
    return this.requestService.get("/location", params);
  }

  async retrieveLocation(id: any) {
    const data = await this.requestService.get(`/location/${id}`);
    if (data && data.imgUrl) {
      const image = await this.requestService.getImage(data.imgUrl);
      if (image) {
        data.image =
          "data:image/png;base64, " +
          new Buffer(image, "binary").toString("base64");
      }
    }
    return data;
  }

  async retrieveLocationCSV(params: any = {}) {
    const result = await this.requestService.get("/location/csv", params);
    const blob = new Blob(["\ufeff", result]);
    fileDownload(blob, `${params.locationType}.csv`);
  }

  async delete(id: any) {
    return this.requestService.delete("/location", id);
  }
}

export default Location;
