import React, { Component } from "react";
import {
  Form,
  Input,
  Radio,
  Divider,
  Icon,
  Button,
  Upload,
  Row,
  Col,
  message,
  Collapse,
  Spin,
} from "antd";
import LayoutSelection from "./components/LayoutSelection";
import CollapseAction from "./components/CollapseAction";
import { RichPreview } from "./components/RichPreview";
import RichmenuService from "../../../services/Richmenu";
import UsersService from "../../../services/Users";

import "./RichMenuForm.css";

const position = ["A", "B", "C", "D", "E", "F"];
const amountPosition = [6, 4, 5, 5, 3, 2]; // amount position depend layout

class RichmenuForm extends Component<any, any> {
  richmenuService = new RichmenuService();
  userService = new UsersService();
  state = {
    uploadDisabled: false,
    loadingRichmenu: false,
    allowFile: false,
    layoutSelection: 0,
    layoutAmountPosition: amountPosition[0],
    menuRadio: 1,
    updateLoading: false,
    richmenuId: undefined,
    richmenu: {
      title: null,
      chatmenu: null,
      layout: -1,
      image: null,
      trackingLabel: null,
    },
    oldFile: undefined,
    imageExist: false,
  };

  async componentWillMount() {
    document.title = "LINE x MTL - CMS Richmenu update";
    const id = this.props.match.params.id;
    this.setState({ richmenuId: id });
  }

  async componentDidMount() {
    this.setState({ updateLoading: true, loadingRichmenu: true });
    const id = this.state.richmenuId;
    const tmpMenu = ["guest", "member"];
    if (!tmpMenu.filter((m) => m === id).length) {
      this.props.history.replace("/richmenu");
    }
    try {
      const result = await this.richmenuService.getRichmenu(id);
      if (result) {
        const menuSelect = result.chatmenu === "Menu" ? 1 : 2;
        await this.setState({ richmenu: result, imageExist: true });
        await this.menuSelect(menuSelect);
        await this.handleLayout(result.layout);
        var file = this.dataURLtoFile(this.state.richmenu.image, "newPic.png");
        this.setState({
          oldFile: {
            file: file,
            fileList: [file],
          },
        });
        this.userService.saveActivity({
          moduleName: "richmenu",
          actionName: "detail",
          contentId: id === "guest" ? 1 : 2,
        });
      } else {
        // this.props.history.replace('/richmenu');
      }
    } catch (err) {
      // this.props.history.replace('/richmenu')
    }
    this.setState({ updateLoading: false, loadingRichmenu: false });
  }

  validateAction = (item: any, key: string) => {
    const shortKey = key.substring(key.indexOf("_") + 1);
    if (item.action === "link" && (!item.url || item.url.trim() === "")) {
      message.error("please enter url in Action " + shortKey);
      const textElement = document.getElementById("url_" + shortKey);
      if (textElement) textElement.classList.add("error_input");
      return false;
    }

    if (
      item.action === "link" &&
      !item.url.match(/^(http|https):\/\/[^ "]+$/)
    ) {
      message.error("invalid url " + shortKey);
      message.info("please enter http:// or https://", 5);
      const textElement = document.getElementById("url_" + shortKey);
      if (textElement) textElement.classList.add("error_input");
      return false;
    }

    if (
      item.action === "link" &&
      (!item.description || item.description.trim() === "")
    ) {
      message.error("please enter description in Action " + shortKey);
      const textElement = document.getElementById("descript_" + shortKey);
      if (textElement) textElement.classList.add("error_input");
      return false;
    }

    if (item.action === "link" && item.description.length > 20) {
      message.error(
        "description max length 20 character in Action " + shortKey
      );
      const textElement = document.getElementById("descript_" + shortKey);
      if (textElement) textElement.classList.add("error_input");
      return false;
    }

    if (item.action === "text" && (!item.text || item.text.trim() === "")) {
      message.error("please enter text in Action " + shortKey);
      const textElement = document.getElementById("text_" + shortKey);
      if (textElement) textElement.classList.add("error_input");
      return false;
    }

    if (item.action === "text" && item.text.length > 50) {
      message.error("text max length 50 character in Action " + shortKey);
      const textElement = document.getElementById("text_" + shortKey);
      if (textElement) textElement.classList.add("error_input");
      return false;
    }

    return true;
  };

  dataURLtoFile = (dataurl: any, filename: any) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    let hasAction = false;
    let actionPass = true;
    this.props.form.validateFieldsAndScroll(async (err: any, values: any) => {
      let trackingLabels: any = {};
      values["trackingLabel"] = {};
      if (!err) {
        for (const key in values) {
          if (key.indexOf("action_") > -1) {
            const trackingLabel = !!values[key]["trackingLabel"]
              ? values[key]["trackingLabel"]
              : null;
            trackingLabels[key] = { trackingLabel: trackingLabel };
            delete values[key]["trackingLabel"];
            hasAction = true;
            const result = this.validateAction(values[key], key);
            if (actionPass) actionPass = result;
          }
        }
        if (!hasAction) {
          message.error("set you image and type action", 4);
          return;
        }
        if (hasAction && actionPass) {
          this.setState({ updateLoading: true });
          values.trackingLabel = JSON.stringify(trackingLabels);
          const result = await this.richmenuService.saveRichmenu(
            values,
            this.state.richmenuId
          );
          if (result) {
            message.success("saved!", 4);
            setTimeout(() => {
              this.props.history.push("/richmenu");
            }, 1500);
            this.userService.saveActivity({
              moduleName: "richmenu",
              actionName: "update",
              contentId: this.state.richmenuId === "guest" ? 1 : 2,
            });
          } else {
            message.error("Failed to save richmenu", 2);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        }
      } else {
        message.error("Failed to save richmenu", 2);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    });
  };

  handleChangeFile = (info: any) => {
    setTimeout(() => {
      if (info.fileList.length) {
        if (this.state.allowFile) {
          this.setState({ uploadDisabled: true });
          const imgElement = document.getElementById(
            "richimage"
          ) as HTMLImageElement;
          imgElement.src = window.URL.createObjectURL(info.file);
        } else {
          const filename = document.getElementsByClassName(
            "anticon-close"
          )[0] as HTMLElement;
          if (filename) {
            filename.click();
          }
        }
      } else {
        this.setState({ uploadDisabled: false });
      }
    }, 1000);
  };

  beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    this.setState({ allowFile: isJpgOrPng });
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error("Image must smaller than 1MB!");
      this.setState({ allowFile: false });
    }
    return false;
  };

  menuSelect = (index: any) => {
    const { setFieldsValue, getFieldDecorator } = this.props.form;
    if (index === 1) {
      getFieldDecorator("chatmenu");
      setFieldsValue({ chatmenu: "Menu" });
    }
    this.setState({ menuRadio: index });
  };

  handleLayout = (index: number) => {
    const layoutSelection = index;
    const layoutAmountPosition = amountPosition[index];
    this.setState({ layoutSelection, layoutAmountPosition });
  };

  useExistImage = (change: boolean) => {
    this.setState({ imageExist: change });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const richmenu = JSON.parse(JSON.stringify(this.state.richmenu));
    return (
      <Spin spinning={this.state.updateLoading} delay={200}>
        <div className="richmenu-form">
          {this.state.loadingRichmenu ? null : (
            <Form
              layout="vertical"
              className="form"
              onSubmit={this.handleSubmit}
            >
              <h2 style={{ paddingLeft: "8px" }}>Menu settings</h2>
              <Form.Item
                label="Title"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
              >
                {getFieldDecorator("title", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter you title",
                    },
                    {
                      max: 30,
                      message: "Max length 30 character",
                    },
                  ],
                  initialValue:
                    richmenu && richmenu.title ? richmenu.title : null,
                })(<Input data-at="field_title" placeholder="Title" />)}
              </Form.Item>
              <Form.Item
                label="Chat Menu"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
              >
                <Radio.Group
                  defaultValue={
                    !richmenu.chatmenu || richmenu.chatmenu === "Menu" ? 1 : 2
                  }
                >
                  <Radio
                    data-at="radio_bulletin"
                    value={1}
                    onClick={() => this.menuSelect(1)}
                  >
                    Menu
                  </Radio>
                  <Radio
                    data-at="radio_text"
                    value={2}
                    onClick={() => this.menuSelect(2)}
                  >
                    {getFieldDecorator("chatmenu", {
                      initialValue:
                        this.state.menuRadio === 2 && richmenu.chatmenu
                          ? richmenu.chatmenu
                          : "Menu",
                      rules: [
                        {
                          required: this.state.menuRadio === 2,
                          message: "Please enter you menu",
                        },
                        { max: 14, message: "Max length 14 character" },
                      ],
                    })(
                      <Input
                        data-at="field_menu"
                        placeholder="custom menu word"
                        disabled={this.state.menuRadio !== 2}
                      />
                    )}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Select Layout"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                {getFieldDecorator("layout", {
                  initialValue: richmenu.layout,
                  rules: [
                    { required: true, message: "Please select you layout" },
                  ],
                })(<LayoutSelection onChange={this.handleLayout} />)}
              </Form.Item>
              <Divider />
              <h2>Menu content</h2>
              <Row gutter={24}>
                <Col sm={24} md={8}>
                  <Form.Item>
                    {this.state.imageExist ? (
                      <React.Fragment>
                        <RichPreview
                          index={this.state.layoutSelection}
                          showImage={true}
                          image={this.state.richmenu.image}
                        />
                        {getFieldDecorator("upload", {
                          initialValue: this.state.oldFile,
                        })(
                          <Upload
                            accept="image/x-png,image/jpeg"
                            onChange={this.handleChangeFile}
                            beforeUpload={this.beforeUpload}
                          ></Upload>
                        )}
                        <Button onClick={() => this.useExistImage(false)}>
                          Change Image
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <RichPreview
                          index={this.state.layoutSelection}
                          showImage={this.state.uploadDisabled}
                        />
                        <div className="btn-group-upload">
                          {getFieldDecorator(
                            "upload",
                            {}
                          )(
                            <Upload
                              accept="image/x-png,image/jpeg"
                              onChange={this.handleChangeFile}
                              beforeUpload={this.beforeUpload}
                            >
                              <Button
                                data-at="btn_upload"
                                disabled={this.state.uploadDisabled}
                                type="primary"
                              >
                                <Icon type="upload" /> Select File
                              </Button>
                            </Upload>
                          )}
                          {!this.state.imageExist &&
                          this.state.richmenu.image ? (
                            <Button
                              data-at="btn_cancel"
                              type="danger"
                              ghost
                              onClick={() => this.useExistImage(true)}
                              disabled={this.state.uploadDisabled}
                            >
                              <Icon type="close" /> Cancel
                            </Button>
                          ) : null}
                        </div>
                      </React.Fragment>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={24} md={{ span: 15, offset: 1 }}>
                  <h3>Action</h3>
                  <Form.Item validateStatus="validating">
                    {this.state.uploadDisabled || this.state.imageExist ? (
                      position.map((value, index) => {
                        if (index < this.state.layoutAmountPosition) {
                          const name = "action_" + value;
                          const initialValue = richmenu[name] || {
                            action: "noaction",
                            url: "https://",
                          };
                          return getFieldDecorator(name, { initialValue })(
                            <CollapseAction
                              headerName={value}
                              key={index}
                              richType={this.state.richmenuId}
                            />
                          );
                        } else {
                          return null;
                        }
                      })
                    ) : (
                      <Collapse>
                        <Collapse.Panel
                          header="A"
                          key="A"
                          disabled
                        ></Collapse.Panel>
                      </Collapse>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  data-at="btn_submit"
                  size="large"
                  icon="save"
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </Spin>
    );
  }
}

export default Form.create({ name: "Richmenu_Form" })(RichmenuForm);
