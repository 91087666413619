import React, { Component } from "react";
import {
  Form,
  Button,
  Input,
  Spin,
  Icon,
  Upload,
  Checkbox,
  InputNumber,
  Switch,
  message,
} from "antd";
import LocationService from "../../../services/Location";
import "./LocationForm.css";
import UsersService from "../../../services/Users";

class LocationForm extends Component<any, any> {
  _isMounthed = false;
  locationService = new LocationService();
  userService = new UsersService();
  state = {
    mode: "create",
    locationType: "",
    location: {
      name: undefined,
      detail: undefined,
      telephone: undefined,
      website: "https://",
      latitude: undefined,
      longitude: undefined,
      address: undefined,
      image: undefined,
      trackingLabel: undefined,
      status: "active",
    },
    allowFile: false,
    updateLoading: false,
    newImage: false,
    uploadDisabled: false,
    currentImage: undefined,
  };

  async componentWillMount() {
    const locationType = this.props.match.params.type;
    if (!locationType) this.props.history.replace("/");

    const id = this.props.match.params.id;
    if (id) {
      if (id === "create") {
        document.title = `LINE x MTL - CMS Location ${
          locationType[0].toUpperCase() + locationType.slice(1)
        } [Create]`;
        this.setState({ mode: "create", locationType });
      } else {
        this.setState({ updateLoading: true, mode: "update", locationType });
        document.title = `LINE x MTL - CMS Location ${locationType} [Edit]`;
      }
    } else {
      this.props.history.replace(`/location/${locationType}`);
    }
  }

  async componentDidMount() {
    this._isMounthed = true;
    const id = this.props.match.params.id;
    const locationType = this.props.match.params.type;
    if (id !== "create") {
      let location = await this.locationService.retrieveLocation(id);
      if (location) {
        location.trackingLabel = !!location.trackingLabel
          ? location.trackingLabel
          : `${locationType}-map-${id}, ${locationType}-tel-${id}`;

        if (this._isMounthed) {
          this.setState({
            location,
            mode: "update",
            currentImage: location.image,
          });
        }
        this.userService.saveActivity({
          moduleName: `location ${this.state.locationType}`,
          actionName: "detail",
          contentId: id,
        });
      } else {
        this.props.history.replace(`/location/${this.state.locationType}`);
      }
    }
    this.setState({ updateLoading: false });
  }

  async componentWillUnmount() {
    this._isMounthed = false;
    delete this.locationService;
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const mode = this.state.mode;
        let location = JSON.parse(JSON.stringify(this.state.location));
        location = { ...values };
        location.image = values.upload;
        location.status = values.status ? "active" : "inactive";
        location.locationType = this.state.locationType;
        delete location.upload;

        if (mode === "create") {
          this.setState({ updateLoading: true });
          const result = await this.locationService.createLocation(location);
          console.log(result);
          if (result && result.id > -1) {
            this.userService.saveActivity({
              moduleName: `location ${this.state.locationType}`,
              actionName: "create",
              contentId: result.id,
            });
            message.success("create successful");
            setTimeout(() => {
              this.props.history.replace(
                `/location/${this.state.locationType}`
              );
            }, 800);
          } else {
            message.error("create failed");
          }
          this.setState({ updateLoading: false });
        }

        if (mode === "update") {
          this.setState({ updateLoading: true });
          location.id = this.props.match.params.id;
          const result = await this.locationService.update(location);
          this.setState({ updateLoading: false });
          if (result && result.update) {
            this.userService.saveActivity({
              moduleName: `location ${this.state.locationType}`,
              actionName: "update",
              contentId: location.id,
            });
            message.success("update successful");
          } else {
            message.error("update failed");
          }
          setTimeout(() => {
            this.props.history.replace(`/location/${this.state.locationType}`);
          }, 800);
        }
      }
    });
  };

  handleChangeFile = (info: any) => {
    setTimeout(() => {
      if (info.fileList.length) {
        if (this.state.allowFile) {
          this.setState({ uploadDisabled: true });
          const imgElement = document.getElementById(
            "imagePreview"
          ) as HTMLImageElement;
          imgElement.src = window.URL.createObjectURL(info.file);
        } else {
          const filename = document.getElementsByClassName(
            "anticon-close"
          )[0] as HTMLElement;
          if (filename) {
            filename.click();
          }
        }
      } else {
        this.setState({ uploadDisabled: false });
      }
    }, 500);
  };

  beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    this.setState({ allowFile: isJpgOrPng });
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false;
  };

  newImageHandle = async (e: any) => {
    const checked = e.target.checked;
    if (!checked) {
      this.setState({ uploadDisabled: false });
    }
    this.setState({ newImage: checked });
  };

  checkBlank = (rule: any, value: any, callback: any) => {
    if (value.trim() === "" || value.trim().length === 0) {
      rule.message = "Do not use white space.";
      callback(false);
    }
    callback();
  };

  render() {
    const locationType = this.state.locationType;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const uploadImage =
      this.state.mode === "create" ||
      (this.state.mode === "update" && this.state.newImage) ? (
        <Form.Item label="Image">
          <div className="image-preview">
            <img
              data-at="img_preview"
              alt={this.state.locationType}
              id="imagePreview"
              style={
                this.state.uploadDisabled
                  ? { display: "block" }
                  : { display: "none" }
              }
            />
            <Icon
              type="file-image"
              style={
                this.state.uploadDisabled
                  ? { display: "none" }
                  : { display: "block" }
              }
            />
          </div>
          {getFieldDecorator("upload", {
            rules: [{ required: true }],
            values: this.state.location.image,
          })(
            <Upload
              accept="image/x-png,image/jpeg"
              onChange={this.handleChangeFile}
              beforeUpload={this.beforeUpload}
            >
              <Button data-at="btn_upload" disabled={this.state.uploadDisabled}>
                <Icon type="upload" /> Select File
              </Button>
            </Upload>
          )}
          <span style={{ fontSize: "0.85em" }}>
            *Image ratio 21:13 (Example 620x384, 500x310 pixel or similar size)
          </span>
        </Form.Item>
      ) : null;

    const currentImage =
      this.state.mode === "update" ? (
        <React.Fragment>
          <Form.Item label="Current Image">
            <div className="image-preview">
              <Icon
                type="file-image"
                style={
                  this.state.currentImage
                    ? { display: "none" }
                    : { display: "block" }
                }
              />
              <img
                data-at="img_currentImage"
                alt="product"
                id="imagePreviewUpdate"
                src={this.state.currentImage}
                style={
                  this.state.currentImage
                    ? { display: "block" }
                    : { display: "none" }
                }
              />
            </div>
          </Form.Item>
          <Form.Item label="New Image">
            <Checkbox
              data-at="btn_newImage"
              onChange={this.newImageHandle}
            ></Checkbox>
          </Form.Item>
        </React.Fragment>
      ) : null;

    return (
      <div className="location-form">
        <Spin spinning={this.state.updateLoading} delay={200}>
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            className="form"
          >
            <Form.Item label="Name">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: `Please enter your ${
                      locationType[0].toUpperCase() + locationType.slice(1)
                    } name`,
                  },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.location.name,
              })(
                <Input
                  data-at="field_name"
                  placeholder={`${
                    locationType[0].toUpperCase() + locationType.slice(1)
                  } name`}
                />
              )}
            </Form.Item>

            <Form.Item label="Detail">
              {getFieldDecorator("detail", {
                rules: [
                  {
                    required: true,
                    message: `Please enter your ${
                      locationType[0].toUpperCase() + locationType.slice(1)
                    } detail`,
                  },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.location.detail,
              })(
                <Input.TextArea
                  data-at="field_detail"
                  maxLength={255}
                  placeholder={`${
                    locationType[0].toUpperCase() + locationType.slice(1)
                  } detail`}
                />
              )}
            </Form.Item>

            <Form.Item label="Tracking Label">
              {getFieldDecorator("trackingLabel", {
                initialValue: this.state.location.trackingLabel,
              })(
                <Input
                  disabled
                  data-at="field_tracking_label"
                  placeholder="Tracking label generate in database"
                />
              )}
            </Form.Item>

            <Form.Item label="Telephone">
              {getFieldDecorator("telephone", {
                rules: [
                  {
                    required: true,
                    message: `Please enter your ${
                      locationType[0].toUpperCase() + locationType.slice(1)
                    } telephone number`,
                  },
                  {
                    pattern: /^\d*$/,
                    message: "Please decimal only",
                  },
                ],
                initialValue: this.state.location.telephone,
              })(
                <Input
                  data-at="field_telephone"
                  placeholder="0123456789"
                  maxLength={10}
                />
              )}
            </Form.Item>

            <Form.Item label="Website">
              {getFieldDecorator("website", {
                initialValue: this.state.location.website,
              })(
                <Input data-at="field_website" placeholder="website https://" />
              )}
            </Form.Item>

            <Form.Item label="Latitude">
              {getFieldDecorator("latitude", {
                rules: [
                  { required: true, message: "Please enter your latitude" },
                ],
                initialValue: this.state.location.latitude,
              })(
                <InputNumber
                  data-at="field_latitude"
                  placeholder=""
                  style={{ width: "250px" }}
                />
              )}
            </Form.Item>

            <Form.Item label="Longitude">
              {getFieldDecorator("longitude", {
                rules: [
                  { required: true, message: "Please enter your longitude" },
                ],
                initialValue: this.state.location.longitude,
              })(
                <InputNumber
                  data-at="field_longitude"
                  placeholder=""
                  style={{ width: "250px" }}
                />
              )}
            </Form.Item>

            <Form.Item label="Address">
              {getFieldDecorator("address", {
                initialValue: this.state.location.address,
              })(
                <Input.TextArea
                  data-at="field_address"
                  maxLength={255}
                  placeholder={`${
                    locationType[0].toUpperCase() + locationType.slice(1)
                  } address`}
                />
              )}
            </Form.Item>
            {uploadImage}
            {currentImage}
            <Form.Item label="Status">
              {getFieldDecorator("status", {
                valuePropName: "checked",
                initialValue: this.state.location.status === "active",
              })(
                <Switch
                  data-at="btn_status"
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              )}
            </Form.Item>

            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
              <Button
                data-at="btn_submit"
                icon="save"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
              <Button
                type="default"
                htmlType="button"
                style={{ marginLeft: "8px" }}
                onClick={() => this.props.history.goBack()}
                data-at="btn_back"
              >
                Back
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create({ name: "Location_Form" })(LocationForm);
