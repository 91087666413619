import React, { Component } from 'react';
import { Button, Table } from 'antd';
import RichmenuService from '../../services/Richmenu';
import './RichMenu.css';
import UsersService from '../../services/Users'

class RichMenu extends Component <any, any> {
  richmenuService = new RichmenuService();
  userService = new UsersService()

  state = {
    loading: true,
    dataTable: [
      {
        id: 'guest',
        key: 1,
        title: 'MTL Rich Menu',
        template: 'Template Layout 1'
      },
      {
        id: 'member',
        key: 2,
        title: 'MTL Rich Menu',
        template: 'Template Layout 1'
      }
    ],
    columns: [
      {
        title: 'No',
        key: 'row_no',
        width: '5%',
        render: (text: any, record: any) => (
          <span>{record.key}</span>
        )
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (text: any, record: any) => {
          if (record.key === 1)
            return <span>{text} <strong>Guest</strong></span>;
          else return <span>{text} <strong>Member</strong></span>;
        }
      },
      {
        title: 'Template',
        dataIndex: 'template',
        key: 'template',
        render: (text: any, record: any) => {
          return <span data-at={'label_template-' + record.key}>{text}</span>
        }
      },
      {
        title: 'Action',
        key: 'action',
        width: '25%',
        render: (text: any, record: any) => (
          <div className="btn-row">
            <Button ghost type="primary"
              icon="edit" size="small"
              onClick={() => this.handleEditRichmenu(record)}
              data-at={'btn_edit-' + record.id}
              className="btn-custom-blue"
            >
              Edit
            </Button>
          </div>
        ),
      }
    ]
  }

  componentWillMount() {
    document.title = 'LINE x MTL - CMS Richmenu';
  }
  
  async componentDidMount() {
    const layouts = await this.richmenuService.getLayoutRichmenu();
    if (layouts) {
      const table = this.state.dataTable;
      layouts.forEach((layout: any) => {
        table.filter((row) => row.id === layout.id).map((row) => row.template = 'Template Layout ' + (layout.layout + 1));
      });
      this.setState({ dataTable: table })
      this.userService.saveActivity({
        moduleName:'richmenu',
        actionName: 'view'
      })
    }
    
    this.setState({ loading: false });
  }

  handleEditRichmenu = (record: any) => {
    if (record.key === 1) 
      this.props.history.push('/richmenu/guest');
    else this.props.history.push('/richmenu/member');
  }

  render() {
    return (
      <div className="richmenu-list">
         <div className="card-table">
          <Table columns={this.state.columns}
            loading={this.state.loading} 
            dataSource={this.state.dataTable} 
            pagination={false}
            />
        </div>
      </div>
    )
  }
}

export default RichMenu;