import axios from 'axios';
import { message } from 'antd';


class HttpRequestService {
  API_URL: any;

  constructor() {
    this.API_URL = process.env.REACT_APP_API_URL;
  }

  private handleError(err: any, isImage: any = null) {
    try {
      console.log(err);
      if (err.toString().trim() === 'Error: Network Error') {
        message.warn('Cannot connect to server!');
        return;
      }

      const response = err.response;
      const statusCode = response.status;

      if (statusCode === 404 && isImage) {
        message.warn(`Can't download image, Please upload new image.`, 3);
        return null;
      }

      if (statusCode === 401) {
        window.location.href = '/login?logout';
        return null;
      }

      if (statusCode >= 400 && statusCode < 500) {
        const errorMessage = response.data.error.message;
        if (statusCode === 404) message.info(errorMessage, 3);
        else message.error(errorMessage, 4);
      }

      if (statusCode === 500 || err.toString().trim().indexOf('Error: Request failed with status code 500') > -1) {
        message.error('Internal server error', 3);
      }
    } catch (error) {
      console.log(error)
    }

  }

  async get(url: string, params: any = null) {
    const AUTHORIZATION = { 'Authorization': 'bearer ' + window.localStorage.getItem('refresh_token') };
    return await axios.get(this.API_URL + url,
      {
        headers: { ...AUTHORIZATION },
        params
      }).then(res => {
        return res.data.data;
      }).catch(err => {
        this.handleError(err);
      });
  }

  async getImage(url: string) {
    return await axios.get(url,
      {
        responseType: 'arraybuffer'
      }).then(res => {
        return res.data;
      }).catch(err => {
        this.handleError(err, true);
      });
  }

  async getImageAuth(url: string) {
    const AUTHORIZATION = { 'Authorization': 'bearer ' + window.localStorage.getItem('refresh_token') };
    return await axios.get(this.API_URL + url,
      {
        headers: { ...AUTHORIZATION },
        responseType: 'arraybuffer'
      }).then(res => {
        return res.data;
      }).catch(err => {
        this.handleError(err, true);
      });
  }

  async post(url: string, data: any = {}, isFormData = false) {
    const AUTHORIZATION = { 'Authorization': 'bearer ' + window.localStorage.getItem('refresh_token') };
    if (isFormData) {
      return await axios({
        method: 'POST',
        url: this.API_URL + url,
        data: data,
        headers: {
          ...AUTHORIZATION,
          'Content-Type': 'multipart/form-data',
        }
      }).then(res => {
        return res.data.data;
      }).catch(err => {
        this.handleError(err);
      });
    } else {
      return await axios.post(this.API_URL + url, { ...data }, {
        headers: { ...AUTHORIZATION },
      }).then(res => {
        return res.data.data;
      }).catch(err => {
        this.handleError(err);
      });
    }

  }

  async put(url: string, data: any = {}, isFormData = false) {
    const AUTHORIZATION = { 'Authorization': 'bearer ' + window.localStorage.getItem('refresh_token') };
    if (isFormData) {
      return await axios({
        method: 'PUT',
        url: this.API_URL + url,
        data: data,
        headers: {
          ...AUTHORIZATION,
          'Content-Type': 'multipart/form-data',
        }
      }).then(res => {
        return res.data.data;
      }).catch(err => {
        this.handleError(err);
      });
    } else {
      return await axios.put(this.API_URL + url, { ...data }, {
        headers: { ...AUTHORIZATION },
      }).then(res => {
        return res.data.data;
      }).catch(err => {
        this.handleError(err);
      });
    }

  }

  async delete(url: string, id: any) {
    const AUTHORIZATION = { 'Authorization': 'bearer ' + window.localStorage.getItem('refresh_token') };
    return await axios.delete(this.API_URL + url + '/' + id,
      {
        headers: { ...AUTHORIZATION }
      }).then(res => {
        return res.data.data;
      }).catch(err => {
        this.handleError(err);
      });
  }
}

export default HttpRequestService;