import React, { Component } from "react";
import { Layout, Menu, Icon, Breadcrumb, Modal } from "antd";
import { NavLink } from "react-router-dom";
import DashboardService from '../services/Dashboard';

import menu from "../config/menu.json";
import Authen from "../services/Authentication";
import "./PageLayout.css";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class PageLayout extends Component<any, any> {
  state = {
    collapsed: false,
    broken: false,
    breadcrumbItem: []
  };

  handleNavClick = () => {
    const isEpired = Authen.isTokenExpired();
    if (isEpired) {
      this.props.history.push("/login?logout");
    }
    const collapsedBtn = document.getElementsByClassName(
      "ant-layout-sider-zero-width-trigger"
    )[0] as HTMLElement;
    if (collapsedBtn) {
      collapsedBtn.click();
    }
  };

  componentDidMount() {
    const computedMatch = this.props.computedMatch;
    this.generateBreadcrumb(computedMatch);
  }

  componentWillReceiveProps(newProps: any) {
    if (newProps.computedMatch.url !== this.props.computedMatch.url) {
      const computedMatch = newProps.computedMatch;
      this.generateBreadcrumb(computedMatch);
    }
  }

  generateBreadcrumb = (computedMatch: any) => {
    let items = computedMatch.url.split("/");
    items = items.filter((item: any) => item.length);
    this.setState({ breadcrumbItem: items });
  };

  getLocationIcon = (menu: any) => {
    if (menu === 'hospital')
      return <Icon type="medicine-box"></Icon>;
    if (menu === 'branch')
      return <Icon type="branches"></Icon>;
    return null;
  }

  clearCache = async () => {
    const dashboardService = new DashboardService();
    await dashboardService.clearCache();
    Modal.info({
      title: 'Success',
      content: (
        <p>Flush all cache!</p>
      )
    });
    console.log('clear all cache');
  }

  render() {
    const userRole = window.localStorage.getItem("role");
    const username = window.localStorage.getItem("username");
    const paths = this.props.path.split("/").filter((m: any) => m !== "");
    let selectedKey = paths[0];
    const openKey = selectedKey;
    if (!selectedKey) selectedKey = "/";
    if (selectedKey === 'location') {
      selectedKey = paths[1];
    }

    const menuElem = (
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[selectedKey]}
        defaultOpenKeys={[openKey]}
        selectable={true}
      >
        {menu
          .filter(m => m.key && m.role)
          .map((m, i) => {
            const ok = m.role.filter(role => role === userRole).length;
            if (ok && m.url) {
              return (
                <Menu.Item key={m.key} onClick={() => this.handleNavClick()}>
                  <NavLink to={m.url} data-at={"label-menu-" + m.key}>
                    <Icon type={m.icon} />
                    <span className="nav-text">{m.text}</span>
                  </NavLink>
                </Menu.Item>
              );
            } else if (ok && m.submenu) {
              return (
                <SubMenu
                  key={m.key}
                  title={
                    <span>
                      <Icon type={m.icon} />
                      <span>{m.text}</span>
                    </span>
                  }
                >
                  {m.submenu
                    ? m.submenu.map(s => {
                        return (
                          <Menu.Item key={s.key} onClick={() => this.handleNavClick()}>
                            <NavLink to={s.url} data-at={"label-menu-" + s.key}>
                              <Icon type={s.icon} />
                              <span className="nav-text">{s.text}</span>
                            </NavLink>
                          </Menu.Item>
                        );
                      })
                    : null}
                </SubMenu>
              );
            }
            return null;
          })}
      </Menu>
    );

    const breadCrumb = (
      <Breadcrumb separator=">" style={{ margin: "16px", marginBottom: "0" }}>
        <Breadcrumb.Item key="0" data-at="label-breadcrumb-dashboard">
          <Icon type="dashboard"></Icon>&nbsp;
          <NavLink to="/">Dashboard</NavLink>
        </Breadcrumb.Item>
        {this.state.breadcrumbItem.map((item: any, i: any) => {
          let link = "";
          this.state.breadcrumbItem.forEach((val: any, j: any) => {
            if (j <= i) link += "/" + val;
          });
          const me = menu.filter((m: any) => m.key === item)[0];
          
          const text = me
            ? me.text
            : item.charAt(0).toUpperCase() + item.slice(1);
          return (
            <Breadcrumb.Item
              key={i + 1}
              data-at={
                "label-breadcrumb-" +
                (me && me.key ? me.key : text.toLowerCase())
              }
            >
              {me ? <Icon type={me.icon}></Icon> : this.getLocationIcon(item)}
              &nbsp;<NavLink to={link}>{text}</NavLink>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );

    return (
      <Layout>
        <Header className="header-bar">
          <div className="logo" onDoubleClick={() => {this.clearCache()}}></div>
          <Menu
            theme="light"
            mode="horizontal"
            className="header-menu"
            selectedKeys={[selectedKey]}
            selectable={true}
          >
            <Menu.Item key="profile" className="menu-item-logout">
              <NavLink to="/profile" data-at="btn-profile">
                <Icon type="user" />{" "}
                <span data-at="label-username">{username}</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="logout" className="menu-item-logout">
              <NavLink to="/login?logout" data-at="btn-logout">
                <Icon type="logout" /> Logout
              </NavLink>
            </Menu.Item>
          </Menu>
          {/* <div className="menu-username"  style={{ display: (username) ? 'block' : 'none' }}>
            <Icon type="user" /> <span data-at="label-username">{username}</span>
          </div> */}
        </Header>

        <Layout>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
              this.setState({ broken });
            }}
            onCollapse={(collapsed, type) => {
              this.setState({ collapsed });
            }}
            style={
              !this.state.collapsed && this.state.broken
                ? {
                    zIndex: 999,
                    height: "100vh",
                    position: "absolute",
                    left: 0
                  }
                : this.state.broken
                ? {
                    zIndex: 999,
                    height: "100vh",
                    position: "absolute"
                  }
                : {}
            }
            theme="light"
          >
            {menuElem}
          </Sider>
          <Layout className="layout-content">
            {breadCrumb}
            <Content style={{ margin: "16px" }}>
              <div style={{ minHeight: "78vh" }}>{this.props.children}</div>
            </Content>
            <Footer className="layout-footer">
              Line x MTL CMS ©2019 Created by 1Moby
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default PageLayout;
