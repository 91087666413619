import jwtDecode from 'jwt-decode';
import axios from 'axios';
import RequestService from './HttpRequestService';
import { message } from 'antd';

const API_URL = process.env.REACT_APP_API_URL;
const errorMessage: any = {
  'Network Error': 'Cannot connect to server!',
  'Reqeust failed with status code 500': 'Internal server error!',
};

class Authentication {
  requestService: RequestService = new RequestService();
  static isTokenExpired(): boolean {
    let result = true;
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      const payload: any = jwtDecode(refreshToken);
      const now = new Date();
      const d = new Date(0);
      d.setUTCSeconds(payload.exp);
      if (now <= d) {
        result = false;
      }
    }
    return result;
  }

  static async login(username: string, password: string) {
    try {
      const response = await axios.post(API_URL + '/auth', { username, password });
      const data = response.data.data;
      const refresh_token = data.refreshToken;
      const role = data.role;
      let refreshExpired = new Date();
      refreshExpired.setTime(refreshExpired.getTime() + (4 * 60 * 1000) + (30 * 1000));
      localStorage.setItem('refresh_token', refresh_token);
      localStorage.setItem('role', role);
      localStorage.setItem('username', username);
      localStorage.setItem('refresh_expired', refreshExpired.getTime().toString());
      return true;
    } catch (err) {
      const msg = err.message;
      console.log(msg);
      if (errorMessage[msg]) {
        message.warning(errorMessage[msg]);
      }

      return false;
    }
  }

  static async getRefresh(token: string) {
    try {
      const response = await axios.get(API_URL + '/auth/refresh', {
        headers: {
          'Authorization': `bearer ${token}`
        }
      });
      return response.data.data;
    } catch (err) {
      const msg = err.message;
      const error = err.response;
      if (errorMessage[msg]) {
        message.warning(errorMessage[msg]);
      }

      if (error && error.status === 401) window.location.href = '/login?logout';
      else {
        return null;
      }
    }
  }

  static clearLocalStorage() {
    localStorage.clear();
  }

  static logout(history: any) {
    history.push('/login?logout');
  }
}

export default Authentication;