import RequestService from './HttpRequestService';
import fileDownload from 'js-file-download';

class Product {
  requestService = new RequestService();

  async createProduct(params: any = {}) {
    const formData = new FormData();
    formData.append('name', params.name);
    formData.append('detail', params.detail);
    formData.append('linkUrl', params.linkUrl);
    formData.append('status', params.status);
    formData.append('file', params.image.file);
    return this.requestService.post('/product', formData, true);
  }

  async update(product: any = {}) {
    const id = product.id;
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('detail', product.detail);
    formData.append('linkUrl', product.linkUrl);
    formData.append('status', product.status);
    if (product.image) {
      formData.append('file', product.image.file);
    }
    return this.requestService.put(`/product/${id}`, formData, true);
  }

  async retrieveProducts(params: any = {}) {
    return this.requestService.get('/product', params);
  }

  async retrieveProduct(id: any) {
    const data = await this.requestService.get(`/product/${id}`);
    const image = await this.requestService.getImage(data.imgUrl)
    if (image) {
      data.image = 'data:image/png;base64, ' + new Buffer(image, 'binary').toString('base64');
    }
    return data;
  }

  async retrieveProductsCSV(params: any = {}) {
    const result = await this.requestService.get('/product/csv', params);
    const blob = new Blob(["\ufeff", result]);
    fileDownload(blob, 'product.csv');
  }


  async delete(id: any) {
    return this.requestService.delete('/product', id);
  }

  getUsedProduct(id: any) {
    return this.requestService.get(`/product/used/${id}`);
  }

}

export default Product;
