import React, { Component } from "react";
import {
  Form,
  Switch,
  Button,
  Input,
  Select,
  message,
  Checkbox,
  Spin,
  Modal,
} from "antd";
import "./UserForm.css";
import UsersService from "../../../services/Users";

const { Option } = Select;
const charPass = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

class UserForm extends Component<any, any> {
  userService = new UsersService();
  state = {
    mode: "create",
    user: {
      username: undefined,
      password: undefined,
      email: undefined,
      firstname: undefined,
      lastname: undefined,
      tel: undefined,
      role: undefined,
      status: "active",
    },
    confirmDirty: false,
    newPassword: false,
    updateLoading: false,
  };

  handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  async componentWillMount() {
    if (this.props.match.params.id) {
      if (this.props.match.params.id === "create") {
        document.title = "LINE x MTL - CMS User [Create]";
        this.setState({ mode: "create" });
      } else {
        try {
          this.setState({ updateLoading: true });
          document.title = "LINE x MTL - CMS User [Edit]";
          const id = this.props.match.params.id;
          const user = await this.userService.retrieveUser(id);
          if (user) {
            this.userService.saveActivity({
              moduleName: "User Management",
              actionName: "detail",
              contentId: id,
            });
            this.setState({ user, mode: "update" });
          } else {
            this.props.history.replace("/users");
          }
          this.setState({ mode: "update", updateLoading: false });
        } catch (err) {
          this.props.history.replace("/users");
        }
      }
      await this.generatePassword();
    } else {
      this.props.history.replace("/users");
    }
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        const mode = this.state.mode;
        const user = this.state.user;
        user.username = values.username;
        user.password = values.password;
        user.email = values.email;
        user.firstname = values.firstname;
        user.lastname = values.lastname;
        user.tel = values.tel;
        user.role = values.role;
        user.status = values.status ? "active" : "inactive";

        if (mode === "create") {
          this.setState({ updateLoading: true });
          const result = await this.userService.create(user);
          this.setState({ updateLoading: false });
          if (result && result.id) {
            this.userService.saveActivity({
              moduleName: "User Management",
              actionName: "create",
              contentId: result.id,
            });
            message.success("create successful");
            Modal.success({
              title: "User Info",
              content: (
                <div>
                  <Input.TextArea
                    readOnly
                    id="user-info"
                    value={
                      "Username: " +
                      this.state.user.username +
                      "\nPassword: " +
                      this.state.user.password
                    }
                  />
                </div>
              ),
              okText: "Copy to clipboard",
              onOk: () => {
                const copyText: any = document.getElementById("user-info");
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                document.execCommand("copy");
                setTimeout(() => {
                  this.props.history.replace("/users");
                }, 1200);
              },
            });
          }
        }

        if (mode === "update") {
          this.setState({ updateLoading: true });
          const result = await this.userService.update(this.state.user);
          this.setState({ updateLoading: false });
          if (result && result.update) {
            const user: any = this.state.user;
            this.userService.saveActivity({
              moduleName: "User Management",
              actionName: "update",
              contentId: user.id,
            });
            message.success("update successful");
          }
          if (this.state.newPassword) {
            Modal.success({
              title: "User Info",
              content: (
                <div>
                  <Input.TextArea
                    readOnly
                    id="user-info"
                    value={
                      "Username: " +
                      this.state.user.username +
                      "\nPassword: " +
                      this.state.user.password
                    }
                  />
                </div>
              ),
              okText: "Copy to clipboard",
              onOk: () => {
                const copyText: any = document.getElementById("user-info");
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                document.execCommand("copy");
                setTimeout(() => {
                  this.props.history.replace("/users");
                }, 1000);
              },
            });
          } else {
            setTimeout(() => {
              this.props.history.replace("/users");
            }, 1000);
          }
        }
      }
    });
  };

  compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  newPasswordHandle = (e: any) => {
    const newPassword = e.target.checked;
    if (newPassword) this.generatePassword();
    this.setState({ newPassword });
  };

  generatePassword = () => {
    const stUser = this.state.user;
    const user = JSON.parse(JSON.stringify(stUser));
    const password = this.randomPassword();
    if (!this.validatePassword(password)) {
      this.generatePassword();
    } else {
      user.password = password;
      this.setState({ user });
    }
  };

  randomPassword = () => {
    let password = "";
    for (let i = 0; i < 8; i++) {
      password += charPass[Math.floor(Math.random() * charPass.length)];
    }
    return password;
  };

  validatePassword = (password: string) => {
    let hasNumber = false;
    let hasLower = false;
    let hasUpper = false;
    for (let i = 0; i < password.length; i++) {
      const character = password[i];
      if (!isNaN(Number(character))) {
        hasNumber = true;
      } else {
        if (character === character.toUpperCase()) {
          hasUpper = true;
        }
        if (character === character.toLowerCase()) {
          hasLower = true;
        }
      }
    }

    return hasNumber && hasUpper && hasLower;
  };

  checkBlank = (rule: any, value: any, callback: any) => {
    if (value.trim() === "" || value.trim().length === 0) {
      rule.message = "Do not use white space.";
      callback(false);
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const username = window.localStorage.getItem("username");

    const passwordComponent =
      this.state.mode === "create" ||
      (this.state.mode === "update" && this.state.newPassword) ? (
        <React.Fragment>
          <Form.Item label="" style={{ display: "none" }}>
            {getFieldDecorator("password", {
              initialValue: this.state.user.password,
            })(
              <Input
                data-at="field_password"
                placeholder=""
                type="hidden"
                autoComplete="new-password"
                readOnly
              />
            )}
          </Form.Item>
        </React.Fragment>
      ) : null;

    const newPassword =
      this.state.mode === "update" ? (
        <Form.Item label="Password">
          <Checkbox data-at="btn_newPassword" onChange={this.newPasswordHandle}>
            Generate new password
          </Checkbox>
        </Form.Item>
      ) : null;

    return (
      <div className="user-form">
        <Spin spinning={this.state.updateLoading} delay={200}>
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            className="form"
          >
            <Form.Item label="Username" hasFeedback>
              {getFieldDecorator("username", {
                rules: [
                  { required: true, message: "Please enter your username" },
                  { min: 4, message: "Username must be at least 4 characters" },
                  {
                    required: true,
                    pattern: new RegExp("^[a-zA-Z0-9]*$"),
                    message: "Please use alphabetic and numeric only",
                  },
                ],
                initialValue: this.state.user.username,
              })(
                <Input
                  data-at="field_username"
                  readOnly={this.state.mode === "update"}
                  placeholder="Username"
                  autoComplete="new-password"
                />
              )}
            </Form.Item>

            <Form.Item label="Email" hasFeedback>
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "Please enter your email" },
                  { validator: this.checkBlank },
                  {
                    required: true,
                    pattern: new RegExp(
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ),
                    message: "Email invalid format",
                  },
                ],
                initialValue: this.state.user.email,
              })(<Input data-at="field_email" placeholder="Email" />)}
            </Form.Item>

            <Form.Item label="Firstname" hasFeedback>
              {getFieldDecorator("firstname", {
                rules: [
                  { required: true, message: "Please enter your firstname" },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.user.firstname,
              })(<Input data-at="field_firstname" placeholder="Firstname" />)}
            </Form.Item>

            <Form.Item label="Lastname" hasFeedback>
              {getFieldDecorator("lastname", {
                rules: [
                  { required: true, message: "Please enter your lastname" },
                  { validator: this.checkBlank },
                ],
                initialValue: this.state.user.lastname,
              })(<Input data-at="field_lastname" placeholder="Lastname" />)}
            </Form.Item>

            <Form.Item label="Phone Number">
              {getFieldDecorator("tel", {
                initialValue: this.state.user.tel,
              })(<Input data-at="field_phone" placeholder="Phone Number" />)}
            </Form.Item>

            <Form.Item label="Role" hasFeedback>
              {getFieldDecorator("role", {
                rules: [{ required: true, message: "Please enter your role" }],
                initialValue: this.state.user.role,
              })(
                <Select
                  data-at="field_role"
                  disabled={
                    username === this.state.user.username &&
                    this.state.mode === "update"
                  }
                  placeholder="Role"
                >
                  <Option data-at="role_option-admin" value="admin">
                    Administrator
                  </Option>
                  <Option data-at="role_option-staff" value="staff">
                    Staff
                  </Option>
                  <Option data-at="role_option-staff" value="callcenter">
                    Call Center
                  </Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item label="Status">
              {getFieldDecorator("status", {
                valuePropName: "checked",
                initialValue: this.state.user.status === "active",
              })(
                <Switch
                  data-at="btn_status"
                  disabled={
                    username === this.state.user.username &&
                    this.state.mode === "update"
                  }
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              )}
            </Form.Item>

            {username === this.state.user.username &&
            this.state.mode === "update"
              ? null
              : newPassword}

            {passwordComponent}

            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
              <Button data-at="btn_submit" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create({ name: "Product_Category" })(UserForm);
