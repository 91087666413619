import React, { Component } from "react";
import { Form, Collapse, Select, Input } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import "./CollapseAction.css";

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class CollapseAction extends Component<any & FormComponentProps> {
  state = {
    action: "noaction",
    url: undefined,
    description: undefined,
    text: undefined,
    trackingLabel: undefined,
  };

  constructor(props: any & FormComponentProps) {
    super(props);
    const value = props.value || {};
    this.state = {
      action: value.action,
      url: value.url,
      description: value.description,
      text: value.text,
      trackingLabel: value.trackingLabel,
    };
    // this.triggetChange({ action, url, description, text });
  }

  static getDerivedStateFromProps(nextProps: any) {
    if ("value" in nextProps) {
      return nextProps.value || {};
    }
    return null;
  }

  handleSelect = (action: string) => {
    const oldAction = this.state.action;
    let url = this.state.url;
    let description = this.state.description;
    let text = this.state.text;
    if (action !== oldAction) {
      url = undefined;
      description = undefined;
      text = undefined;
    }
    this.setState({ action, url, description, text });
    this.triggetChange({ action, url, description, text });
  };

  handleUrl = (e: any) => {
    const url = e.target.value;
    this.setState({ url });
    this.triggetChange({ url });
    if (url.length) {
      e.target.classList.remove("error_input");
    }
  };

  handleDescription = (e: any) => {
    const description = e.target.value;
    this.setState({ description });
    this.triggetChange({ description });
    if (description.length) {
      e.target.classList.remove("error_input");
    }

    if (description.length && description.length > 20) {
      e.target.classList.add("error_input");
    }
  };

  handleText = (e: any) => {
    const text = e.target.value;
    this.setState({ text });
    this.triggetChange({ text });
    if (text.length) {
      e.target.classList.remove("error_input");
    }

    if (text.length && text.length > 50) {
      e.target.classList.add("error_input");
    }
  };

  handleTrackingLabel = (e: any) => {
    const reg =
      /^[\u0E00-\u0E7Fa-zA-Z0-9@~`!@#$%^&*()_=+\\\\';:\"\\\/?>.<,-\[\]\{\}]+$/;
    if (e.target.value === "" || reg.test(e.target.value)) {
      const trackingLabel = e.target.value;
      this.setState({ trackingLabel });
      this.triggetChange({ trackingLabel });
      if (trackingLabel.length) {
        e.target.classList.remove("error_input");
      }

      if (trackingLabel.length && trackingLabel.length > 50) {
        e.target.classList.add("error_input");
      }
    }
  };

  triggetChange = (changedValue: any) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange({
        ...this.state,
        ...changedValue,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Collapse className="collapes-action">
          <Panel header={this.props.headerName} key={this.props.headerName}>
            <Form.Item
              label="Action type"
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
            >
              <Select
                placeholder="Select"
                onChange={this.handleSelect}
                value={this.state.action}
                data-field={"field_action-" + this.props.id}
              >
                <Option value="link">Link</Option>
                <Option value="text">Text</Option>
                {this.props.richType === "guest" ? (
                  <Option value="toMember">Go to member menu</Option>
                ) : (
                  <Option value="toGuest">Go to guest menu</Option>
                )}
                <Option value="noaction">No action</Option>
              </Select>
            </Form.Item>
            <Form.Item
              required
              label=""
              wrapperCol={{ span: 20, offset: 4 }}
              labelCol={{ span: 4 }}
              style={
                this.state.action === "link"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Input
                placeholder="Example https://www.example.com"
                onChange={this.handleUrl}
                value={this.state.url}
                id={"url_" + this.props.headerName}
                data-at={"field_url-" + this.props.id}
              />
            </Form.Item>
            <Form.Item
              label="Action label (20 characters or less)"
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              style={
                this.state.action === "link"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <TextArea
                placeholder="Enter a description of this action"
                style={{ resize: "none" }}
                onChange={this.handleDescription}
                value={this.state.description}
                id={"descript_" + this.props.headerName}
                data-at={"field_descript_url-" + this.props.id}
              />
            </Form.Item>
            <Form.Item
              label=""
              wrapperCol={{ span: 20, offset: 4 }}
              style={
                this.state.action === "text"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <TextArea
                placeholder="Enter text"
                style={{ resize: "none" }}
                onChange={this.handleText}
                value={this.state.text}
                id={"text_" + this.props.headerName}
                data-at={"field_text-" + this.props.id}
              />
            </Form.Item>
            <Form.Item
              required
              label="Tracking label"
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              style={{ display: "block" }}
            >
              <Input
                placeholder="Enter a tracking label of this action"
                onChange={this.handleTrackingLabel}
                value={this.state.trackingLabel}
                maxLength={45}
                id={"tracking_label" + this.props.headerName}
                data-at={"field_tracking_label-" + this.props.id}
              />
            </Form.Item>
          </Panel>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default Form.create<any>()(CollapseAction);
