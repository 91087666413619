import RequestService from './HttpRequestService';
import fileDownload from 'js-file-download';

class Users {
  requestService = new RequestService();

  async update(user: any = {}) {
    const id = user.id;
    return this.requestService.put(`/users/${id}`, user);
  }

  async retrieveUsers(params: any = {}) {
    return this.requestService.get('/users', params);
  }

  async retrieveUser(id: any) {
    return this.requestService.get(`/users/${id}`, {});
  }

  async retrieveUsersCSV(params: any = {}) {
    const result = await this.requestService.get('/users/csv', params);
    const blob = new Blob(["\ufeff", result]);
    fileDownload(blob, 'users.csv');
  }

  async create(user: any) {
    return this.requestService.post('/users', user);
  }

  async delete(id: any) {
    return this.requestService.delete('/users', id);
  }

  async activity(params: any = {}) {
    return this.requestService.get('/users-activity', params);
  }
  
  async getUserActivityModule() {
    return this.requestService.get('/users-activity/list/module');
  }

  async getUserActivityAction() {
    return this.requestService.get('/users-activity/list/action');
  }

  async saveActivity(params: any) {
    return this.requestService.post('/users-activity', params);
  }
}

export default Users;
