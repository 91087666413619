import RequestService from "./HttpRequestService";
import fileDownload from "js-file-download";

class TrackingUrl {
  requestService = new RequestService();

  async retrieveTrackingUrls(params: any = {}) {
    return this.requestService.get("/tracking/url", params);
  }

  async retrieveTrackingUrl(id: any) {
    return this.requestService.get(`/tracking/url/${id}`);
  }

  async create(productCategory: any) {
    return this.requestService.post("/tracking/url", productCategory);
  }

  async update(productCategory: any) {
    const id = productCategory.id;
    return this.requestService.put(`/tracking/url/${id}`, productCategory);
  }

  async delete(id: any) {
    return this.requestService.delete("/tracking/url", id);
  }
}

export default TrackingUrl;
