import RequestService from "./HttpRequestService";

class Richmenu {
  requestService = new RequestService();

  async saveRichmenu(richmenu: any = {}, id: any) {
    const formData = new FormData();
    for (const key in richmenu) {
      if (key === "upload") {
        formData.append("file", richmenu[key].file);
      } else if (key.indexOf("action") > -1) {
        formData.append(key, JSON.stringify(richmenu[key]));
      } else {
        formData.append(key, richmenu[key]);
      }
    }
    return this.requestService.post(`/richmenu/${id}`, formData, true);
  }

  async getRichmenu(id: any) {
    const result = await this.requestService.get(`/richmenu/${id}`);
    if (result) {
      const image = await this.getRichmenuImage(id);
      if (image) {
        result.image =
          "data:image/png;base64, " +
          new Buffer(image, "binary").toString("base64");
      }
    }
    return result;
  }

  async getRichmenuImage(id: any) {
    return this.requestService.getImageAuth(`/richmenu/image/${id}`);
  }

  async getLayoutRichmenu() {
    return this.requestService.get(`/richmenu/layouts`);
  }

  async saveTrackingLabel(id: any, trackingLabel: any) {
    return this.requestService.post(`/richmenu/tracking/${id}`, trackingLabel);
  }
}

export default Richmenu;
